import actionTypes from '../action/type'
const initialState = {
  isLoading: false,
  settingMaster: {},
}
function mainReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADINGSTART:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.LOADINGSTOP:
      return {
        ...state,
        isLoading: false,
      }
    case actionTypes.GET_STORE_TEMPLATE:
      return {
        ...state,
      }
    case actionTypes.GET_SETTING_MASTER:
      return {
        ...state,
        settingMaster: action.payload,
      }
    default:
      return state
  }
}
export default mainReducer
