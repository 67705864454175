import { useLoader } from "@react-three/fiber";
import {
    EffectComposer,
    SSR,
    Bloom,
    LUT,
    SSAO,
} from "@react-three/postprocessing";
import { useControls } from "leva";
import { LUTCubeLoader, Resizer, KernelSize } from "postprocessing";

export function Effects() {
    //   const texture = useLoader(LUTCubeLoader, '/src/F-6800-STD.cube')
    // const { enabled, ...props } = useControls({
    //     enabled: true,
    //     // temporalResolve: true,
    //     // STRETCH_MISSED_RAYS: true,
    //     // USE_MRT: true,
    //     // USE_NORMALMAP: true,
    //     // USE_ROUGHNESSMAP: true,
    //     // ENABLE_JITTERING: false,
    //     // ENABLE_BLUR: true,
    //     // DITHERING: false,
    //     // temporalResolveMix: { value: 0.9, min: 0, max: 1 },
    //     // temporalResolveCorrectionMix: { value: 0.4, min: 0, max: 1 },
    //     // maxSamples: { value: 0, min: 0, max: 1 },
    //     // resolutionScale: { value: 1, min: 0, max: 1 },
    //     // blurMix: { value: 0.2, min: 0, max: 1 },
    //     // blurKernelSize: { value: 8, min: 0, max: 8 },
    //     // BLUR_EXPONENT: { value: 10, min: 0, max: 20 },
    //     // rayStep: { value: 0.5, min: 0, max: 1 },
    //     // intensity: { value: 2.5, min: 0, max: 5 },
    //     // maxRoughness: { value: 1, min: 0, max: 1 },
    //     // jitter: { value: 0.3, min: 0, max: 5 },
    //     // jitterSpread: { value: 0.25, min: 0, max: 1 },
    //     // jitterRough: { value: 0.1, min: 0, max: 1 },
    //     // roughnessFadeOut: { value: 1, min: 0, max: 1 },
    //     // rayFadeOut: { value: 0, min: 0, max: 1 },
    //     // MAX_STEPS: { value: 20, min: 0, max: 20 },
    //     // NUM_BINARY_SEARCH_STEPS: { value: 6, min: 0, max: 10 },
    //     // maxDepthDifference: { value: 5, min: 0, max: 10 },
    //     // maxDepth: { value: 1, min: 0, max: 1 },
    //     // thickness: { value: 3, min: 0, max: 10 },
    //     // ior: { value: 1.45, min: 0, max: 2 },
    // });
    return (
        // enabled && (
        //     <EffectComposer disableNormalPass>
        //         <SSR {...props} />
        //         <Bloom
        //             luminanceThreshold={0}
        //             mipmapBlur
        //             luminanceSmoothing={0}
        //             intensity={0}
        //         />
        //         {/* <LUT lut={texture} /> */}
        //     </EffectComposer>
        // )

        // <EffectComposer enabled disableNormalPass>
        //     {/* <SSR intensity={0.2}/> */}
        //     <Bloom />
        //   </EffectComposer>

        <EffectComposer enabled disableNormalPass>
            {/* <SSR intensity={0.15} ENABLE_BLUR jitter={false} /> */}
            <Bloom
                // luminanceThreshold={1}
                // mipmapBlur
                intensity={0.8} // The bloom intensity.
                blurPass={undefined} // A blur pass.
                width={Resizer.AUTO_SIZE} // render width
                height={Resizer.AUTO_SIZE} // render height
                kernelSize={KernelSize.LARGE} // blur kernel size
                luminanceThreshold={0.8} // luminance threshold. Raise this value to mask out darker elements in the scene.
                luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
            />
        </EffectComposer>
        // <EffectComposer multisampling={8}>
        //   <Bloom kernelSize={3} luminanceThreshold={0.6} luminanceSmoothing={0.04} intensity={0.6} />
        //   {/* <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} /> */}
        // </EffectComposer>
    );
}
