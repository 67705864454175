import Button from 'react-bootstrap/Button'
import {
  React,
  useState,
  useRef,
  useCallback,
  useSelector,
  useEffect,
} from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'
import Config from '../config'
import actionTypes from '../store/action/type'
import { getByPath } from '../store/action/authAction'
import Model from './Model'
import { Link } from 'react-router-dom'
import '@google/model-viewer'

const GalleryGlbModel = (props) => {
  const {
    path,
    type,
    currentstatus,
    onHide,
    action,
    methodtype,
    valImage,
    galleryFileTypeData,
    galleryFileTypeDataImage,
    galleryData,
    valueGallery,
    valGlb,
    primaryGalleryData,
  } = props
  const [bgRemoveModel, setBgRemoveModel] = useState(false)
  const [bgRemove, setBgRemove] = useState(false)
  // console.log(galleryData, 'gallery data here')
  // const galleryData23 = useSelector((state) => state.ownerReducer.gallery);
  // console.log(galleryData23,"galleryData23");
  // console.log(primaryGalleryData, "primaryGalleryData");
  // const galleryFileTypeData = useSelector(
  //   (state) => state.ownerReducer.gallery
  // );
  // const galleryFileTypeDataImage = useSelector(
  //   (state) => state.ownerReducer.gallery
  // );
  const dispatch = useDispatch()
  const [fileType, setFileType] = useState('glb')

  const nav = () => {
    // window.location.reload();
  }
  const deleteHandler = (e, id) => {
    console.log(id, 'id')
    dispatch(
      getByPath(
        Config.apiUploadPath + '/' + 'deletefile' + '/' + id,
        {},
        actionTypes.DELETE_GALLERY_IMAGE,
        Config.method.delete
      )
    )
    handleFileTypeData(e, 'glb')
    props.onHide(false)
    // setTimeout(() => props.onShow(true), 1000);
  }

  // useEffect(() => {
  //   dispatch(
  //     getByPath(
  //       Config.apiOwnerPath +
  //         Config.endPoints.gallery +
  //         "/" +
  //         "glb" +
  //         "/" +
  //         673 +
  //         "/" +
  //         327,
  //       {},
  //       actionTypes.GET_GALLERY,
  //       Config.method.get
  //     )
  //   );
  // }, []);
  const [fileImg, setFileImg] = useState()
  function uploadProductImage(e) {
    console.log(e.target.files, ' targeted files')
    setFileImg(URL.createObjectURL(e.target.files[0]))
    //const imgURl = URL.createObjectURL(e.target.files[0]);
    dispatch(
      getByPath(
        Config.endPoints.UploadFileToUser,
        {},
        actionTypes.GALLERY_IMAGE_URL,
        Config.method.post
      )
    )
  }
  const [formData, setFormData] = useState(new FormData())
  ///////new change /////////////////////////////////////////////////////////////
  const fileInputRef = useRef(null)
  const clickHandler = useCallback(() => {
    fileInputRef.current.click()
    console.log('clickHandler')
  }, [])
  let user = JSON.parse(localStorage.getItem('userInfo'))
  const changeHandler = useCallback(
    (e, from) => {
      console.log(e.target.files, 'hello')
      // setFileImage(URL.createObjectURL(e.target.files[0]));
      // const imgURl12 = e.target.files[0];
      // console.log(imgURl12, "glbUrl");
      if (from === 'productGlbImg') {
        setBgRemoveModel(true)
      }
      // var formData = new FormData()
      formData.append('file', e.target.files[0])
      //console.log(formData, "formDataimg");
      formData.append('userId', user._id)
      formData.append('type', from)
      //console.log(formData, "formData122");
      if (from !== 'productGlbImg') {
        dispatch(
          getByPath(
            Config.apiUploadPath + Config.endPoints.uploadFileToGallery,
            formData,
            actionTypes.ADD_GALLERY_IMAGE,
            Config.method.post
          )
        )
        props.onHide(false)
      }
      console.log('worling')
      // if (from === 'productGlbImg') {

      // }
      // setTimeout(() => props.onShow(true), 1000);
    },
    [dispatch]
  )

  const handleFileTypeData = (e, fileType) => {
    // console.log(fileType, "fileType");
    setFileType(fileType)
    //setFileTypeData(galleryFileTypeData);
  }
  function getSeletedUrl(e, fileUrlId, actionType) {
    console.log(fileUrlId, 'fileUrlId')
    console.log(valImage, 'valImage')
    valueGallery([...valImage, fileUrlId])
    props.onHide(false)
  }
  function getSeletedGlbUrl(e, fileUrlId, actionType) {
    // console.log(fileUrlId, "fileUrlId");
    // console.log(valImage, "valImage");
    // valueGallery([...valImage, fileUrlId]);
    //valueGallery(...valImage, fileUrlId);
    valGlb(fileUrlId)
    // dispatch({
    //   type: actionType,
    //   payload: fileUrlId,
    // })
    props.onHide(false)
  }

  return (
    <>
      <Modal
        {...props}
        size='xxl-down'
        aria-labelledby='contained-modal-title-vcenter'
        fullscreen
        className='p-0'
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        {props.type === 'glb' && (
          <>
            <div className='d-flex justify-content-between header boxShdw galHeader align-items-center px-20'>
              <div className='h4 fw-400 lightGreyColor mb-0'>Gallery</div>
              <div>
                {/* productGlb glb btn */}
                {props.type === 'glb' && (
                  <>
                    {fileType === 'glb' && (
                      <button
                        className='btn siteBtn fs-12 btnYellow me-4'
                        id='GlbFileBtn'
                        onClick={clickHandler}

                        // style=""
                      >
                        <i className='bi bi-upload me-2'>
                          {' '}
                          {/* <input type="file" onChange={uploadProductImage} /> */}
                        </i>
                        <input
                          type='file'
                          multiple
                          className='d-none'
                          ref={fileInputRef}
                          onChange={(e) => {
                            changeHandler(e, 'productGlb')
                            // handleFileTypeData(e, 'glb')
                          }}
                          accept='.glb'
                          //from="productImages"
                        />
                        Upload Glb Files
                      </button>
                    )}

                    {/* productImages img btn */}
                    {fileType === 'image' && (
                      <button
                        className='btn siteBtn fs-12 btnYellow me-4'
                        id='GlbFileBtn'
                        onClick={clickHandler}

                        // style=""
                      >
                        <i className='bi bi-upload me-2'>
                          {' '}
                          {/* <input type="file" onChange={uploadProductImage} /> */}
                        </i>
                        <input
                          type='file'
                          multiple
                          className='d-none'
                          ref={fileInputRef}
                          onChange={(e) => {
                            changeHandler(e, 'productGlbImg')
                            handleFileTypeData(e, 'glb')
                          }}
                          accept='image/*'
                          //from="productImages"
                        />
                        Upload Image Files
                      </button>
                    )}
                  </>
                )}

                {/* productImage img btn */}
                <button
                  type='button'
                  className='lightGreyColor border-0 bg-transparent p-0'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={(e) => {
                    props.onHide(false)
                    handleFileTypeData(e, 'glb')
                    //nav();
                  }}
                >
                  <i className='bi bi-x-lg'></i>
                </button>
              </div>
            </div>
            <main className='main' id='main'>
              <div className='sideBar transition text-white position-fixed'>
                <div className='sideBarHeader align-items-center p-10'>
                  <div className='p-10 align-items-center d-flex h-100 w-100 d-none'>
                    <input
                      type='text'
                      className='form-control siteInput rounded-0 fs-14'
                      placeholder='Search here...'
                    />
                  </div>
                  <div className='ps-2'>
                    <p
                      className='fs-14 mb-0 fw-600 text-truncate'
                      data-bs-toggle='tooltip'
                      data-bs-placement='bottom'
                      data-bs-original-title='How to use`'
                    >
                      Get started
                    </p>
                    <p className='fs-10 mb-0 text-truncate'> To upload </p>
                  </div>
                </div>
                <div className='sidebarMenu position-relative'>
                  <ul>
                    <li className=''>
                      {/* <button id="showGlbGallery" onClick="handleGlbData">
                      <span className="mr-10 fs-18">
                        <i className="bi bi-badge-3d"></i>
                      </span>
                      <span>GLB</span>
                    </button> */}
                      <a
                        //id="showGlbGallery"
                        onClick={(e) => {
                          handleFileTypeData(e, 'glb')
                        }}
                      >
                        <span className='mr-10 fs-18'>
                          <i className='bi bi-badge-3d'></i>
                        </span>
                        <span>GLB</span>
                      </a>
                    </li>
                    <li>
                      {/* <button id="showImageGallery" onClick="handleImageData">
                      <span className="mr-10 fs-18">
                        <i className="bi bi-images"></i>
                      </span>
                      <span>Images</span>
                    </button> */}
                      <a
                        id='showImageGallery'
                        onClick={(e) => {
                          handleFileTypeData(e, 'image')
                        }}
                      >
                        <span className='mr-10 fs-18'>
                          <i className='bi bi-images'></i>
                        </span>
                        <span>Images</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mainBox transition d-flex flex-column'>
                {fileType === 'glb' && (
                  <div id='nav-home' style={{}}>
                    <div className='d-flex flex-wrap p-20'>
                      {/* .filter((item) => item.data.type === "productGlb") */}
                      {galleryFileTypeData?.glbFiles?.length > 0 ? (
                        galleryFileTypeData?.glbFiles?.map((gallery) => (
                          <>
                            <div
                              className='galImg overflow-hidden'
                              id='imgCol_0'
                            >
                              <div className='d-flex overflow-hidden h-100 align-items-center justify-content-center'>
                                {/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(
                                  gallery.fileSrc
                                ) ? (
                                  <img
                                    src={gallery.fileSrc}
                                    className='thumbnail'
                                  />
                                ) : (
                                  // <ModelViewer type='glb' src={product.productFile} />
                                  <model-viewer
                                    src={gallery.fileSrc}
                                    alt='A 3D model of a Toy Train'
                                    camera-controls
                                    style={{ maxHeight: '300px' }}
                                  ></model-viewer>
                                )}
                              </div>
                              <div className='p-10 actionBox position-absolute w-100 transition d-flex justify-content-between'>
                                <button
                                  className='btn fs-12 rounded-0 btn-sm btnRed'
                                  onClick={(e) => {
                                    deleteHandler(e, gallery._id)
                                  }}
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  title='Delete'
                                  data-bs-target='#confirmModal'
                                >
                                  <i className='bi bi-trash3'></i>
                                </button>

                                <button
                                  className='btn btnTeal fs-12 rounded-0 btn-sm'
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  title='Use it'
                                  onClick={(e) => {
                                    getSeletedGlbUrl(
                                      e,
                                      gallery.fileSrc,
                                      'GALLERY_GLB_URL'
                                    )
                                  }}
                                >
                                  <i className='bi bi-check-lg'></i>
                                </button>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div
                          className='row mb-4 p-1 overflow-auto text-center'
                          style={{ height: `60vh` }}
                        >
                          <p className='mt-4 rOheading'>
                            <b className='fs-20'>Your gallery is empty</b>
                            <br />
                            <a
                              className='fs-18 text-center mt-2'
                              onClick={clickHandler}
                            >
                              <input
                                type='file'
                                multiple
                                className='d-none'
                                ref={fileInputRef}
                                onChange={(e) => {
                                  changeHandler(e, 'productGlb')
                                }}
                                accept='.glb'
                                //from="productImages"
                              />
                              Click here to upload one
                            </a>
                          </p>
                        </div>
                      )}
                      {/* )} */}
                    </div>
                  </div>
                )}
                {fileType === 'image' && (
                  <div id='nav-home' style={{}}>
                    <div className='d-flex flex-wrap p-20'>
                      {/* {fileTypeData
                      .filter((item) => item.data.type === "productImages")
                      .map((gallery) => ( */}
                      {galleryFileTypeData?.prodGlbImg?.length > 0 ? (
                        galleryFileTypeData?.prodGlbImg?.map((gallery) => (
                          <>
                            <div
                              className='galImg overflow-hidden'
                              id='imgCol_0'
                            >
                              <div className='d-flex overflow-hidden h-100 align-items-center justify-content-center'>
                                <img
                                  className='thumbnail'
                                  src={gallery?.fileSrc}
                                />
                              </div>
                              <div className='p-10 actionBox position-absolute w-100 transition d-flex justify-content-between'>
                                <button
                                  className='btn fs-12 rounded-0 btn-sm btnRed'
                                  onClick={(e) => {
                                    deleteHandler(e, gallery._id)
                                  }}
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  title='Delete'
                                  data-bs-target='#confirmModal'
                                >
                                  <i className='bi bi-trash3'></i>
                                </button>

                                <button
                                  className='btn btnTeal fs-12 rounded-0 btn-sm'
                                  onClick={(e) => {
                                    getSeletedGlbUrl(
                                      e,
                                      gallery.fileSrc,
                                      'GALLERY_GLB_URL'
                                    )
                                  }}
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  title='Use it'
                                >
                                  <i className='bi bi-check-lg'></i>
                                </button>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div
                          className='row mb-4 p-1 overflow-auto text-center'
                          style={{ height: `60vh` }}
                        >
                          <p className='mt-4 rOheading'>
                            <b className='fs-20'>Your gallery is empty</b>
                            <br />
                            <a
                              className='fs-18 text-center mt-2'
                              onClick={clickHandler}
                            >
                              <input
                                type='file'
                                multiple
                                className='d-none'
                                ref={fileInputRef}
                                onChange={(e) => {
                                  changeHandler(e, 'productGlbImg')
                                }}
                                accept='image/*'
                                //from="productImages"
                              />
                              Click here to upload one
                            </a>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </main>
          </>
        )}{' '}
        {props.type === 'productPrimaryImg' && (
          <>
            <div className='d-flex justify-content-between header boxShdw galHeader align-items-center px-20'>
              <div className='h4 fw-400 lightGreyColor mb-0'>Gallery</div>
              <div>
                {/* <button
                className="btn siteBtn fs-12 btnYellow me-4"
                id="GlbFileBtn"
                onClick={clickHandler}

           
              >
                <i className="bi bi-upload me-2">
                  {" "}
               
                </i>
                <input
                  type="file"
                  multiple
                  className="d-none"
                  ref={fileInputRef}
                  onChange={(e) => {
                    changeHandler(e, "productImages");
                  }}
                  accept="image/*"
                  //from="productImages"
                />
                Upload Image Files
              </button> */}
                <button
                  className='btn siteBtn fs-12 btnYellow me-4'
                  id='GlbFileBtn'
                  onClick={clickHandler}

                  // style=""
                >
                  <i className='bi bi-upload me-2'>
                    {' '}
                    {/* <input type="file" onChange={uploadProductImage} /> */}
                  </i>
                  <input
                    type='file'
                    multiple
                    className='d-none'
                    ref={fileInputRef}
                    onChange={(e) => {
                      changeHandler(e, 'productPrimaryImg')
                    }}
                    accept='image/*'
                    //from="productImages"
                  />
                  Upload Image Files
                </button>
                <button
                  type='button'
                  className='lightGreyColor border-0 bg-transparent p-0'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    props.onHide(false)
                    //nav();
                  }}
                >
                  <i className='bi bi-x-lg'></i>
                </button>
              </div>
            </div>
            <main className='main' id='main'>
              <div
                className='mainBox transition d-flex flex-column'
                style={{ marginLeft: '0px' }}
              >
                <div className='d-flex flex-wrap p-20'>
                  {/* {fileImg && ( */}
                  {/* .filter((item) => item.isModerated === false) */}
                  {/* .filter((item) => item.isModerated === false)
                input.filter(isEven).map(multiplyByTwo) */}
                  {/* {props.galleryData
                  .filter((item) => item.data.type === "productPrimaryImg")
                  .map((gallery) => ( */}
                  {primaryGalleryData?.map((gallery) => (
                    <>
                      <div className='galImg overflow-hidden' id='imgCol_0'>
                        {/* <input
                      type="hidden"
                      name="file_id"
                      id="file_id_0"
                      value="640ebac908553f805066b1fd"
                    />
                    <input
                      type="hidden"
                      name="fileId_Val"
                      id="fileId_Val"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="file_Col_id"
                      id="file_Col_id"
                      value="imgCol_0"
                    /> */}
                        <div className='d-flex overflow-hidden h-100 align-items-center justify-content-center'>
                          <img
                            className='thumbnail'
                            src={gallery.fileSrc}
                            // src="https://cdn.vosmos.live/metaverse/users/640af8fff8e2720aa8e0fb1d/productImages/2538590.70630002_47382126.06688213_Gucci Shoes For Women.png"
                          />
                        </div>
                        <div className='p-10 actionBox position-absolute w-100 transition d-flex justify-content-between'>
                          <button
                            className='btn fs-12 rounded-0 btn-sm btnRed'
                            onClick={(e) => {
                              deleteHandler(e, gallery._id)
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Delete'
                            data-bs-target='#confirmModal'
                          >
                            <i className='bi bi-trash3'></i>
                          </button>

                          <button
                            className='btn btnTeal fs-12 rounded-0 btn-sm'
                            onClick={(e) => {
                              getSeletedGlbUrl(
                                e,
                                gallery.fileSrc,
                                'GALLERY_GLB_URL'
                              )
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Use it'
                          >
                            <i className='bi bi-check-lg'></i>
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                  {/* )} */}
                </div>
              </div>
            </main>
          </>
        )}
        {props.type === 'image12' && (
          <>
            <div className='d-flex justify-content-between header boxShdw galHeader align-items-center px-20'>
              <div className='h4 fw-400 lightGreyColor mb-0'>Gallery</div>
              <div>
                {/* <button
                className="btn siteBtn fs-12 btnYellow me-4"
                id="GlbFileBtn"
                onClick={clickHandler}

           
              >
                <i className="bi bi-upload me-2">
                  {" "}
               
                </i>
                <input
                  type="file"
                  multiple
                  className="d-none"
                  ref={fileInputRef}
                  onChange={(e) => {
                    changeHandler(e, "productImages");
                  }}
                  accept="image/*"
                  //from="productImages"
                />
                Upload Image Files
              </button> */}
                <button
                  className='btn siteBtn fs-12 btnYellow me-4'
                  id='GlbFileBtn'
                  onClick={clickHandler}

                  // style=""
                >
                  <i className='bi bi-upload me-2'>
                    {' '}
                    {/* <input type="file" onChange={uploadProductImage} /> */}
                  </i>
                  <input
                    type='file'
                    multiple
                    className='d-none'
                    ref={fileInputRef}
                    onChange={(e) => {
                      changeHandler(e, 'productImage')
                    }}
                    accept='image/*'
                    //from="productImages"
                  />
                  Upload Image Files
                </button>
                <button
                  type='button'
                  className='lightGreyColor border-0 bg-transparent p-0'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    props.onHide(false)
                    //nav();
                  }}
                >
                  <i className='bi bi-x-lg'></i>
                </button>
              </div>
            </div>
            <main className='main' id='main'>
              <div
                className='mainBox transition d-flex flex-column'
                style={{ marginLeft: '0px' }}
              >
                <div className='d-flex flex-wrap p-20'>
                  {/* {fileImg && ( */}
                  {/* .filter((item) => item.isModerated === false) */}
                  {/* .filter((item) => item.isModerated === false)
                input.filter(isEven).map(multiplyByTwo) */}
                  {/* {props.galleryData
                  .filter((item) => item.data.type === "productPrimaryImg")
                  .map((gallery) => ( */}
                  {galleryData?.map((gallery) => (
                    <>
                      <div className='galImg overflow-hidden' id='imgCol_0'>
                        {/* <input
                      type="hidden"
                      name="file_id"
                      id="file_id_0"
                      value="640ebac908553f805066b1fd"
                    />
                    <input
                      type="hidden"
                      name="fileId_Val"
                      id="fileId_Val"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="file_Col_id"
                      id="file_Col_id"
                      value="imgCol_0"
                    /> */}
                        <div className='d-flex overflow-hidden h-100 align-items-center justify-content-center'>
                          <img
                            className='thumbnail'
                            src={gallery.fileSrc}
                            // src="https://cdn.vosmos.live/metaverse/users/640af8fff8e2720aa8e0fb1d/productImages/2538590.70630002_47382126.06688213_Gucci Shoes For Women.png"
                          />
                        </div>
                        <div className='p-10 actionBox position-absolute w-100 transition d-flex justify-content-between'>
                          <button
                            className='btn fs-12 rounded-0 btn-sm btnRed'
                            onClick={(e) => {
                              deleteHandler(e, gallery._id)
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Delete'
                            data-bs-target='#confirmModal'
                          >
                            <i className='bi bi-trash3'></i>
                          </button>

                          <button
                            className='btn btnTeal fs-12 rounded-0 btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Use it'
                            onClick={(e) => {
                              getSeletedUrl(
                                e,
                                gallery.fileSrc,
                                'GALLERY_IMAGE_URL'
                              )
                            }}
                          >
                            <i className='bi bi-check-lg'></i>
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                  {/* )} */}
                </div>
              </div>
            </main>
          </>
        )}
        {props.type === 'logos' && (
          <>
            <div className='d-flex justify-content-between header boxShdw galHeader align-items-center px-20'>
              <div className='h4 fw-400 lightGreyColor mb-0'>Gallery</div>
              <div>
                {/* <button
                className="btn siteBtn fs-12 btnYellow me-4"
                id="GlbFileBtn"
                onClick={clickHandler}

           
              >
                <i className="bi bi-upload me-2">
                  {" "}
               
                </i>
                <input
                  type="file"
                  multiple
                  className="d-none"
                  ref={fileInputRef}
                  onChange={(e) => {
                    changeHandler(e, "productImages");
                  }}
                  accept="image/*"
                  //from="productImages"
                />
                Upload Image Files
              </button> */}
                <button
                  className='btn siteBtn fs-12 btnYellow me-4'
                  id='GlbFileBtn'
                  onClick={clickHandler}

                  // style=""
                >
                  <i className='bi bi-upload me-2'>
                    {' '}
                    {/* <input type="file" onChange={uploadProductImage} /> */}
                  </i>
                  <input
                    type='file'
                    multiple
                    className='d-none'
                    ref={fileInputRef}
                    onChange={(e) => {
                      changeHandler(e, 'logos')
                    }}
                    accept='image/*'
                    //from="productImages"
                  />
                  Upload Image Files
                </button>
                <button
                  type='button'
                  className='lightGreyColor border-0 bg-transparent p-0'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    props.onHide(false)
                    //nav();
                  }}
                >
                  <i className='bi bi-x-lg'></i>
                </button>
              </div>
            </div>
            <main className='main' id='main'>
              <div
                className='mainBox transition d-flex flex-column'
                style={{ marginLeft: '0px' }}
              >
                <div className='d-flex flex-wrap p-20'>
                  {/* {fileImg && ( */}
                  {/* .filter((item) => item.isModerated === false) */}
                  {/* .filter((item) => item.isModerated === false)
                input.filter(isEven).map(multiplyByTwo) */}
                  {/* {props.galleryData
                  .filter((item) => item.data.type === "productPrimaryImg")
                  .map((gallery) => ( */}
                  {galleryData?.map((gallery) => (
                    <>
                      <div className='galImg overflow-hidden' id='imgCol_0'>
                        {/* <input
                      type="hidden"
                      name="file_id"
                      id="file_id_0"
                      value="640ebac908553f805066b1fd"
                    />
                    <input
                      type="hidden"
                      name="fileId_Val"
                      id="fileId_Val"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="file_Col_id"
                      id="file_Col_id"
                      value="imgCol_0"
                    /> */}
                        <div className='d-flex overflow-hidden h-100 align-items-center justify-content-center'>
                          <img
                            className='thumbnail'
                            src={gallery.fileSrc}
                            // src="https://cdn.vosmos.live/metaverse/users/640af8fff8e2720aa8e0fb1d/productImages/2538590.70630002_47382126.06688213_Gucci Shoes For Women.png"
                          />
                        </div>
                        <div className='p-10 actionBox position-absolute w-100 transition d-flex justify-content-between'>
                          <button
                            className='btn fs-12 rounded-0 btn-sm btnRed'
                            onClick={(e) => {
                              deleteHandler(e, gallery._id)
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Delete'
                            data-bs-target='#confirmModal'
                          >
                            <i className='bi bi-trash3'></i>
                          </button>

                          <button
                            className='btn btnTeal fs-12 rounded-0 btn-sm'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Use it'
                            onClick={(e) => {
                              getSeletedUrl(
                                e,
                                gallery.fileSrc,
                                'GALLERY_IMAGE_URL'
                              )
                            }}
                          >
                            <i className='bi bi-check-lg'></i>
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                  {/* )} */}
                </div>
              </div>
            </main>
          </>
        )}
      </Modal>
      <Model
        show={bgRemoveModel}
        title={'Are you sure!'}
        description={'You want to remove the Background of the Image!'}
        onHide={() => setBgRemoveModel(false)}
        type={'BgRemove'}
        path={Config.apiUploadPath + Config.endPoints.uploadFileToGallery}
        setBgRemove={setBgRemove}
        formData={formData}
        methodtype={Config.method.post}
        action={actionTypes.ADD_GALLERY_IMAGE}
        productGalleryHide={onHide}
      />
    </>
  )
}

export default GalleryGlbModel
