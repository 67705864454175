const actionTypes = {
  GET_SALES_CHARACTER: "GET_SALES_CHARACTER",
  UPDATE_CHARACTER: "UPDATE_CHARACTER",
  GET_ERRORS: "GET_ERRORS",
  LOADINGSTART: "LOADINGSTART",
  LOADINGSTOP: "LOADINGSTOP",
  SET_ERRORS: "SET_ERRORS",
  REMOVE_ERRORS: "REMOVE_ERRORS",
  REGISTER_USER: "REGISTER_USER",
  LOGIN_USER: "LOGIN_USER",
  USER_LOGGED_OUT: "USER_LOGGED_OUT",
  ADD_NEW_TEMPLATE: "ADD_NEW_TEMPLATE",
  GET_STORE_TEMPLATE: " GET_STORE_TEMPLATE",
  DELETE_STORE_TEMPLATE: "DELETE_STORE_TEMPLATE",
  GET_STORE: "GET_STORE",
  GET_ACTIVE_STORE: "GET_ACTIVE_STORE",
  ADD_NEW_STORE: "ADD_NEW_STORE",
  UPDATE_STORE: "UPDATE_STORE",
  DELETE_STORE: "DELETE_STORE",
  GET_SETTING_MASTER: "GET_SETTING_MASTER",
  FILTER_DATA: "FILTER_DATA",
  GET_PRODUCT: "GET_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_POLL: "GET_POLL",
  GET_FEEDBACK: "GET_FEEDBACK",
  GET_NOTIFICATION: "GET_NOTIFICATION",
  DELETE_POLL: "DELETE_POLL",
  DELETE_FEEDbACK: "DELETE_FEEDbACK",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  ADD_NEW_PRODUCT: "ADD_NEW_PRODUCT",
  ADD_NEW_FEEDBACK: "ADD_NEW_FEEDBACK",
  GALLERY_GLB_URL: "GALLERY_GLB_URL",
  GALLERY_IMAGE_URL: "GALLERY_IMAGE_URL",
  GET_GALLERY: "GET_GALLERY",
  GET_POLL_ID: "GET_POLL_ID",
  ADD_NEW_POLL: "ADD_NEW_POLL",
  UPDATE_POLL: "UPDATE_POLL",
  GET_FEEDBACK_ID: "GET_FEEDBACK_ID",
  GET_NOTIFICATION_ID: "GET_NOTIFICATION_ID",
  ADD_NEW_NOTIFICATION: "ADD_NEW_NOTIFICATION",
  ADD_GALLERY_IMAGE: "ADD_GALLERY_IMAGE",
  GET_IMAGE_GALLERY: "GET_IMAGE_GALLERY",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  UPDATE_FEEDBACK: "UPDATE_FEEDBACK",
  GET_PRODUCT_ID: "GET_PRODUCT_ID",
  GET_PRIMARY_GALLERY: "GET_PRIMARY_GALLERY",
  ALL_CATEGORY: "ALL_CATEGORY",
  SEARCH_FILTER_DATA: "SEARCH_FILTER_DATA",
  GET_METAVERSE_STORE: "GET_METAVERSE_STORE",
  GET_STORE_ID: "GET_STORE_ID",
};

export default actionTypes;
