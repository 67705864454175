import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import Config from '../../../../config'
import actionTypes from '../../../../store/action/type'
import { getByPath } from '../../../../store/action/authAction'
import { useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'

const EditManageSalesCharacter = (props) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const character = useSelector((state) => state.ownerReducer.salesCharacter)
  console.log(character, 'character')

  const [charName, setCharName] = useState(
    character?.salesCharacter?.avatar?.name
  )
  const [charImg, setCharImg] = useState(
    character?.salesCharacter?.avatar?.imageSrc
  )
  console.log(charImg, 'character Image')
  const [position, setPosition] = useState()
  const [active, setActive] = useState(character?.salesCharacter?.active)

  const changeAvtar = (name) => {
    setCharName(name)
    var tmp = character?.avtars?.filter((el) => el.name === name)[0].imageSrc
    setCharImg(tmp)
  }
  const update = () => {
    const values = {
      id: id,
      name: charName,
      active: active,
      positionName: position
        ? position
        : character?.salesCharacterPosition[0]?.positionName,
    }
    console.log(values, 'values')
    dispatch(
      getByPath(
        Config.apiOwnerPath + Config.endPoints.UpdateSalesCharacterInfo,
        values,
        actionTypes.UPDATE_CHARACTER,
        Config.method.post
      )
    )
    // toast.success("successfully update", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    // setTimeout(() => window.location.reload('/'), 1500)
    //window.location.reload(true);
    //navigate("/owner/store");
  }
  useEffect(() => {
    dispatch(
      getByPath(
        Config.apiOwnerPath + Config.endPoints.getSalesCharacterInfo + '/' + id,
        {},
        actionTypes.GET_SALES_CHARACTER,
        Config.method.get
      )
    )
  }, [dispatch])

  return (
    <>
      <Modal
        {...props}
        size='sm-down'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='siteBox p-3  maxWidth450'
      >
        <button
          className='border-0 position-absolute end-0 top-0 rounded-circle p-0 d-flex align-items-center justify-content-center boxShadow zi-2 closeBtn'
          data-dismiss='modal'
          onClick={() => {
            props.onHide(false)
          }}
        >
          <i className='bi bi-x'></i>
        </button>
        <Modal.Body className='siteBoxInner bg-white'>
          <h4 className='fs-15 mb-1'>Manage Sales Character</h4>
          <p className='fs-11 text-muted'>
            Add or edit a sales character in your store
          </p>
          <hr />

          <div className='w-100 '>
            <div className='row d-none'>
              <div className='col-md-12'>
                <model-viewer
                  id='modal-viewer'
                  src=''
                  loading='eager'
                  camera-controls=''
                  touch-action='pan-y'
                  autoplay=''
                  style={{ minHeight: '400px' }}
                  ar-status='not-presenting'
                ></model-viewer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-7'>
                <img alt=''
                  className='text-center center'
                  id='modalviewer'
                  width='50%'
                  src={
                    charImg
                      ? charImg
                      : character?.salesCharacter?.avatar?.imageSrc
                  }
                  style={{ minHeight: '150px' }}
                />
              </div>
              <div className='col-md-5'>
                <div className='w-100 mb-4'>
                  <label for='sStore' className='form-label fs-12'>
                    Select Avatar
                  </label>
                  <div className='mb-2'>
                    <select
                      className='form-select siteInput rounded-0 fs-12 ps-0'
                      name='salescharacter'
                      onChange={(e) => changeAvtar(e.target.value)}
                    >
                      {character?.avtars?.map((char, i) => (
                        <option
                          value={char?.name}
                          selected={char?.name === charName ? 'selected' : ''}
                        >
                          {char?.name}
                        </option>
                      ))}

                      {/* <option value='Sales Character 2'>
                          Sales Character 2
                        </option> */}
                    </select>
                  </div>
                </div>

                <div className='w-100 mb-4'>
                  <label for='sStore' className='form-label fs-12'>
                    Select Positions
                  </label>
                  <div className='mb-2'>
                    <select
                      className='form-select siteInput rounded-0 fs-12 ps-0'
                      name='salescharacterPosition'
                      onChange={(e) => setPosition(e.target.value)}
                    >
                      {/* <option value=''>Select Position</option> */}
                      {character?.salesCharacterPosition?.map((char, i) => (
                        <option value={char.positionName}>
                          {char.positionName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='w-100 mb-4'>
                  <div className='form-check form-switch'>
                    <label
                      for='scene.lights.position'
                      className='form-label fs-10'
                    >
                      Disable/Enable
                    </label>
                    <input
                      className='form-check-input siteSwith pro'
                      id='togBtn'
                      // type='switch'
                      type='checkbox'
                      name='isActive'
                      // role='switch'
                      // value='true'
                      defaultChecked={
                        active ? active : character?.salesCharacter?.active
                      }
                      onChange={() => setActive(!active)}
                    />
                  </div>
                </div>
                <div className='w-100 text-center mt-4'>
                  <button
                    className='btn siteBtn btnTeal '
                    type='button'
                    onClick={update}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>

            <div className='row d-none' hidden=''>
              <div className='col-md-12'>
                <label for='scene.lights.position' className='form-label'>
                  Position
                </label>
                <div className='input-group mb-3'>
                  <span className='input-group-text fs-10'>x</span>
                  <input
                    type='number'
                    className='form-control form-control-sm fs-11'
                    placeholder=''
                    aria-label=''
                    name='scene.salesCharacterPosition.x'
                    value='14.048'
                  />
                  <span className='input-group-text fs-10'>y</span>
                  <input
                    type='number'
                    className='form-control form-control-sm fs-11'
                    placeholder=''
                    aria-label=''
                    value='0'
                    name='scene.salesCharacterPosition.y'
                  />
                  <span className='input-group-text fs-10'>z</span>
                  <input
                    type='number'
                    className='form-control form-control-sm fs-11'
                    placeholder=''
                    aria-label=''
                    value='33.147'
                    name='scene.salesCharacterPosition.z'
                  />
                </div>
              </div>
            </div>
            <div className='row d-none' hidden=''>
              <div className='col-md-12'>
                <label for='scene.lights.position' className='form-label'>
                  Look At
                </label>
                <div className='input-group mb-3'>
                  <span className='input-group-text fs-10'>x</span>
                  <input
                    type='number'
                    className='form-control form-control-sm fs-11'
                    placeholder=''
                    aria-label=''
                    name='scene.salesCharacterLookAt.x'
                    value='0'
                  />
                  <span className='input-group-text fs-10'>y</span>
                  <input
                    type='number'
                    className='form-control form-control-sm fs-11'
                    placeholder=''
                    aria-label=''
                    value='0'
                    name='scene.salesCharacterLookAt.y'
                  />
                  <span className='input-group-text fs-10'>z</span>
                  <input
                    type='number'
                    className='form-control form-control-sm fs-11'
                    placeholder=''
                    aria-label=''
                    value='0'
                    name='scene.salesCharacterLookAt.z'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'></div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditManageSalesCharacter
