import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import wasd from '../assests/img/wasd.png'
import arrows from '../assests/img/arrows.png'
import mouse from '../assests/img/mouse.png'
const Guide = (props) => {
  return (
    <>
      <Modal
        {...props}
        size='sm-down'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='siteBox p-3'
      >
        <button
          className='border-0 position-absolute end-0 top-0 rounded-circle p-0 d-flex align-items-center justify-content-center boxShadow zi-2 closeBtn'
          data-dismiss='modal'
          onClick={() => {
            props.onHide(false)
          }}
        >
          <i className='bi bi-x'></i>
        </button>
        <Modal.Body className='siteBoxInner bg-white'>
          <h6 className=' mb-1 mt-3 text-center'>Quick Guide</h6>
          <p className='fs-14 mb-4 text-center'>
            Here's how you may navigate through this metaverse.
          </p>
          <div className='text-center d-flex justify-content-center'>
            <div className='rounded overflow-hidden btnDarkTransparent modalIcon d-flex align-items-center justify-content-center mb-4 me-2 p-2'>
              <img src={wasd} />
            </div>
            <div className='rounded overflow-hidden btnDarkTransparent modalIcon d-flex align-items-center justify-content-center mb-4 me-2 p-2'>
              <img src={arrows} />
            </div>
            <div className='rounded overflow-hidden btnDarkTransparent modalIcon d-flex align-items-center justify-content-center mb-4 me-2 p-2'>
              <img src={mouse} />
            </div>
          </div>
          <div className='guideText text-center'>
            <h5 className='fs-14 '>Here is how you may navigate.</h5>
            <p className='fs-12'>
              Use WASD or arrow keys to move. Click and drag to look around.
            </p>
            <div className='text-center'>
              <button
                className='btn siteBtn fs-14 btnTeal'
                data-bs-dismiss='modal'
                onClick={() => {
                  props.onHide(false)
                }}
              >
                Got it
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Guide
