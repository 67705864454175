import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Config from '../../../config'
import actionTypes from '../../../store/action/type'
import { getByPath } from '../../../store/action/authAction'
import useReactSpeechSynthesis from 'react-window-speech-synthesis'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'

const IntroTextModel = (props) => {
  const { id, name } = props
  var storeOldData = useSelector((state) => state.ownerReducer.listData)
  var current = storeOldData.filter((el) => el._id === id)
  const dispatch = useDispatch()
  // console.log(current, "current data by id");

  const update = () => {
    const values = {
      introText: introText,
    }
    console.log(values, 'values')
    dispatch(
      getByPath(
        Config.apiOwnerPath + Config.endPoints.updateStore + '/' + id,
        values,
        actionTypes.UPDATE_STORE,
        Config.method.put
      )
    )
    props.onHide(false)

    // Sound Here

    // End

    // toast.success("successfully update", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    setTimeout(() => window.location.reload('/'), 1500)
    //window.location.reload(true);
    //navigate("/owner/store");
  }

  //speech part here

  const [status, setStatus] = useState(false)
  const [voices, speak, spStatus] = useReactSpeechSynthesis({ status: status })
  const [speechStatus, setSpeechStatus] = useState(spStatus)
  // console.log(voices, "voices here");
  const [introText, setIntroText] = useState({
    introText: `Welcome to ${current.name}'s Metaverse store. Immerse yourself in our virtual world and discover the latest products from ${current.name}. Shop for your favorite ${current.name} products in a fully-immersive digital environment, created by Vosmos, using your avatar to navigate and interact with our virtual store.`,
    voiceType: '0',
    volume: '1',
    rate: '1',
    pitch: '1',
    name: null,
  })

  useEffect(() => {
    if (!introText.name) {
      setIntroText({
        ...introText,
        name: voices?.filter((v) => v.default)[0] || voices[0],
      })
    }
  }, [voices])
  useEffect(() => {
    setSpeechStatus(spStatus)
  }, [spStatus])
  useEffect(() => {
    if (id) {
      setIntroText(current[0]?.introText)
    }
  }, [id])

  const handleSoundChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    if (name === 'name') {
      const langValue = voices?.filter((v) => v.name === value)[0]
      setIntroText({
        ...introText,
        [name]: langValue,
      })
      return
    }
    setIntroText({
      ...introText,
      [name]: value,
    })
  }
  const handleSpeech = (data) => {
    switch (data) {
      case 'speak':
        speak(
          introText.introText,
          introText.name,
          introText.pitch,
          introText.rate,
          introText.volume
        )
        return
      case 'pause':
        setStatus(data)
        return
      case 'resume':
        setStatus(data)
        return
      case 'cancel':
        setStatus(data)
        return
      default:
        break
    }
  }

  // end here

  return (
    <>
      <Modal
        {...props}
        size='sm-down'
        aria-labelledby='contained-modal-title-vcenter'
        // rigth
        contentClassName='siteBox p-3  maxWidth450'
        //className="modal-dialog modal-dialog-centered me-5 maxWidth450"
      >
        <button
          className='border-0 position-absolute end-0 top-0 rounded-circle p-0 d-flex align-items-center justify-content-center boxShadow zi-2 closeBtn'
          data-bs-dismiss='modal'
          onClick={() => {
            props.onHide(false)
          }}
        >
          <i className='bi bi-x'></i>
        </button>
        <Modal.Body className='siteBoxInner bg-white'>
          <h4 className='fs-15 mb-1'>Manage Audio Welcome Message</h4>
          <p className='fs-11 text-muted'>
            Manage volume, rate, pitch and message
          </p>
          <hr />
          <div>
            <label for='scene.voiceType' className='form-label fs-12'>
              Select Voice{' '}
            </label>
            <select
              id='voices'
              className='form-select siteInput rounded-0 fs-12'
              name='scene.voiceType'
            >
              {voices?.map((v) => (
                <option key={uuid()} value={v?.name}>{`${v?.name}`}</option>
              ))}
            </select>
            <div className='col-sm-12 p-2'>
              <label
                for='volume'
                className='form-label fs-12'
                style={{ marginRight: `12px` }}
              >
                Manage Volume
              </label>
              <span id='volume-label' className='fs-12 float-end'>
                {introText?.volume}
              </span>
              <div className='vosmosRangeSlider mb-4'>
                <input
                  className='w-100'
                  type='range'
                  min='0'
                  max='1'
                  step='0.1'
                  name='volume'
                  onChange={handleSoundChange}
                  defaultValue={introText?.volume}
                  style={{
                    backgroundSize: `${
                      ((introText?.volume - 0) * 100) / (1 - 0) + '% 100%'
                    }`,
                  }}
                />
              </div>
            </div>

            <div className='col-sm-12 p-2'>
              <label
                for='rate'
                className='form-label fs-12'
                style={{ marginRight: '20px' }}
              >
                Rate
              </label>
              <span id='rate-label' className='fs-12 float-end'>
                {introText?.rate}
              </span>
              <div className='vosmosRangeSlider mb-4'>
                <input
                  className='w-100'
                  type='range'
                  min='0.1'
                  max='10'
                  step='0.1'
                  name='rate'
                  onChange={handleSoundChange}
                  defaultValue={1}
                  style={{
                    backgroundSize: `${
                      ((introText?.rate - 0.1) * 100) / (10 - 0.1) + '% 100%'
                    }`,
                  }}
                />
              </div>
            </div>

            <div className='col-sm-12 p-2'>
              <label
                for='pitch'
                className='form-label fs-12'
                style={{ marginRight: '20px' }}
              >
                Pitch
              </label>

              <span id='pitch-label' className='fs-12 float-end'>
                {introText?.pitch}
              </span>
              <div className='vosmosRangeSlider mb-4'>
                <input
                  className='w-100'
                  type='range'
                  min='0'
                  max='2'
                  step='0.1'
                  id='pitch'
                  name='pitch'
                  onChange={handleSoundChange}
                  defaultValue={1}
                  style={{
                    backgroundSize: `${
                      ((introText?.pitch - 0) * 100) / (2 - 0) + '% 100%'
                    }`,
                  }}
                />
              </div>
            </div>

            <div className='col-sm-12 p-2'>
              <label for='scene.Description' className='form-label fs-10'>
                Store Description
              </label>
              <textarea
                className='form-control form-control-sm fs-12'
                cols='45'
                id='introText'
                rows='5'
                placeholder='Type here...'
                name='introText'
                value={introText?.introText}
                onChange={handleSoundChange}
              ></textarea>
            </div>

            <div className='col-sm-12 mt-2 ps-2'>
              {(spStatus === false ||
                spStatus === 'end' ||
                spStatus === 'error') && (
                <button
                  id='start'
                  className='btn siteBtn btnDarkGrey me-2 mb-2'
                  onClick={() => handleSpeech('speak')}
                >
                  Start
                </button>
              )}
              {(spStatus === 'start' || spStatus === 'resume') && (
                <button
                  id='pause'
                  className='btn siteBtn btnDarkGrey me-2 mb-2'
                  onClick={() => handleSpeech('pause')}
                >
                  Pause
                </button>
              )}
              {spStatus === 'pause' && (
                <button
                  id='resume'
                  className='btn siteBtn btnDarkGrey me-2 mb-2'
                  onClick={() => handleSpeech('resume')}
                >
                  Resume
                </button>
              )}
              {/* {(spStatus === "start" || spStatus === "pause" || spStatus === "resume") && */}
              <button
                id='cancel'
                className='btn siteBtn btnDarkGrey me-2 mb-2'
                onClick={() => handleSpeech('cancel')}
              >
                Cancel
              </button>
              {/* } */}
            </div>
          </div>
          <div className='mb-1 text-center'>
            <button
              className='btn siteBtn btnTeal'
              type='submit'
              onClick={update}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default IntroTextModel
