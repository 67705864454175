import actionTypes from "../action/type";
const initialState = {
  isLoading: false,
  storeById: {},
};
function metaVerseReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADINGSTART:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOADINGSTOP:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_METAVERSE_STORE:
      return {
        ...state,
        storeById: action.payload,
      };
    default:
      return state;
  }
}
export default metaVerseReducer;
