import actionTypes from "../action/type";
const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {},
};
function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADINGSTART:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOADINGSTOP:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.REGISTER_USER:
      // message.success("Added");
      return {
        ...state,
        registeredUser: action.payload,
      };
    case actionTypes.LOGIN_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        // isLoading: true,
      };
    default:
      return state;
  }
}
export default authReducer;
