import React, { useState } from "react";
import Background from "../../../../assests/vosmos/img/CityTop2.jpg";
import VosmosLogo from "../../../../assests/vosmos/img/logo.png";
import SignUpForm from "../signUp/SignUpForm";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getByPath } from "../../../../store/action/authAction";
import actionTypes from "../../../../store/action/type";
import Config from "../../../../config";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { CustomToast } from "../../../commonFunction";
import { Check2 } from "react-bootstrap-icons";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const myStyle = {
    backgroundImage: "url(" + Background + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const { id } = useParams();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(true);
  const [forget, setForget] = useState(false);
  const [email, setEmail] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state?.authReducer);
  const { isAuthenticated, isLoading } = storeData;

  const updatePassword = (password1, password2) => {
    if (password1 === password2) {
      if (
        validator.isStrongPassword(password1, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        dispatch(
          getByPath(
            Config.apiBasePath + Config.endPoints.updateUser + "/" + id,
            {
              password: password1,
            },
            "",
            Config.method.put
          )
        );
        navigate("/");
      } else {
        toast.info(
          <CustomToast
            icon={<Check2 size={20} color="#fff" />}
            title={"Password Not Strong"}
            description={""}
          />
        );
      }
    } else {
      toast.info(
        <CustomToast
          icon={<Check2 size={20} color="#fff" />}
          title={"Password Mismatch"}
          description={""}
        />
      );
    }
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("userInfo"));

    if (isAuthenticated && user.token !== "undefined") {
      navigate("/owner/dashboard");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>My Title</title> */}
        <link
          href="https://digital.kestoneapps.in/vosmos-new/css/custom.css"
          rel="stylesheet"
        />
      </Helmet>
      <div className="authMain w-100 vh-100" style={myStyle}>
        <div id="particles-js" className="authBox h-100 p-5 position-relative">
          <div className="position-relative zIndex1 h-100 d-flex justify-content-center flex-column adjAuthBox">
            <div className="authDesTop position-absolute end-0 top-0 p-2"></div>
            <div className="logo position-absolute top-0 start-0 ps-3">
              <img src={VosmosLogo} alt="" />
            </div>
            <div className="authText p-3 text-white position-relative">
              <div className="mb-3">
                <div className="textShdw">Vosmos</div>
                <span className="fw-300 display-6 lh-1">Welcome to</span>
                <span className="d-block display-5 fw-600 lh-1">Vosmos</span>
              </div>
              <p className="fs-16 fw-light mb-5 me-lg-5">
                The unified world of tomorrow.
              </p>
              {/* <LoginNew /> */}
              {/* {window.location.pathname == '/' ? (
                <LoginNew />
              ) : window.location.pathname == '/register' ? (
                <SignUpForm />
              ) : (
                ''
              )} */}
              <form
                style={{ display: login ? "" : "none" }}
                // onSubmit={loginUser}
              >
                <div className="form-floating mb-1 col-lg-8">
                  <input
                    type="email"
                    className="form-control inputStyle1 ps-1"
                    id="emailID"
                    placeholder="Enter Email ID"
                    name="email"
                    value={email}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="emailID" className="ps-0">
                    Set Password
                  </label>
                </div>
                <div className="form-floating mb-4 col-lg-8">
                  <input
                    type="password"
                    className="form-control inputStyle1 ps-1"
                    id="password"
                    placeholder="Enter password"
                    name="password"
                    defaultValue={password}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="emailID" className="ps-0">
                    Confirm Password
                  </label>
                </div>
                <button
                  type="button"
                  className="btn btn-sm btnTeal px-4 py-2 showOtp"
                  onClick={() => updatePassword(password, confirmPassword)}
                >
                  Submit
                </button>
                <br />
                {/* <div className='mt-2'>
                  <a
                    onClick={() => {
                      setForget(true)
                      setLogin(false)
                    }}
                    class='showForgetPassword mt-4 text-align-center fs-12'
                  >
                    Forget Password?
                  </a>
                </div> */}
                <p className="mt-5 fs-12">
                  Don’t have an account?{" "}
                  {/* <a href='{configData.route.register}' className='showSignUp'>
          </a> */}
                  <Link
                    onClick={() => {
                      setLogin(false);
                      setForget(false);
                    }}
                    className="showSignUp"
                  >
                    SIGN UP
                  </Link>
                </p>
              </form>
            </div>
            <div className="authDesTop authDesBottom position-absolute start-0 bottom-0 p-2"></div>
            <div className="botLineAuth position-absolute start-0 bottom-0 w-100"></div>
          </div>
          <div className="copyRight">
            Copyright@2022. All rights reserved.{" "}
            <a
              href="https://vosmos.world/privacy-policy.html"
              target="_blank"
              className="textTeal position-relative zIndex1"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
