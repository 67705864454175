import actionTypes from '../action/type'
const initialState = {
  isLoading: false,
  //   isAuthenticated: false,
  storeTemplate: [],
}
function adminReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADINGSTART:
      return {
        ...state,
        isLoading: true,
      }
    case actionTypes.LOADINGSTOP:
      return {
        ...state,
        isLoading: false,
      }
    case actionTypes.GET_STORE_TEMPLATE:
      return {
        ...state,
        storeTemplate: action.payload,
      }
    case actionTypes.DELETE_STORE_TEMPLATE:
      console.log('Deleted successfully')
      console.log(state.storeTemplate, 'state.storeTemplate1222')
      const deleteTemplate = state.storeTemplate.filter(
        (item) => item._id !== action.payload._id
      )
      console.log(deleteTemplate, 'deleteTemplate')
      return {
        ...state,
        storeTemplate: deleteTemplate,
      }
    default:
      return state
  }
}
export default adminReducer
