import axios from "axios";
import { useSelector } from "react-redux";

const setAuthToken = (token) => {
  // var token = sessionStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem("userInfo"));
  // const users = useSelector((state) => state.authReducer.user);
  // console.log(users.lastName, "usersidebar");
  if (user.token || token) {
    //  console.log(token, "tokenAuth");
    if (user.token) {
      axios.defaults.headers.common["token"] = "bearer " + user.token;
    } else {
      axios.defaults.headers.common["token"] = "bearer " + token;
    }
  } else {
    delete axios.defaults.headers.common["token"];
  }
};
export default setAuthToken;
