export const CustomToast = ({ icon, title, description }) => {
  return (
    <div className="d-flex align-items-center toastBoxNew">
      <div className="check me-3 rounded-circle d-flex align-items-center justify-content-center">
        {icon}
      </div>
      <div className="d-flex flex-column darkGreyColor">
        <span className="fs-15 fw-500">{title}</span>
        <span className="fs-12">{description}</span>
      </div>
    </div>
  );
};
