import React, { useCallback, useEffect, useRef } from "react";
import "../../assests/vosmos/css/metaverse.css";
import { Suspense, useState } from "react";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";

import {
    Html,
    useProgress,
    Stats,
    Sky,
    Environment,
    PerspectiveCamera,
    OrbitControls,
    useGLTF,
    Preload,
    Stage,
} from "@react-three/drei";
import SceneLoader from "./modules/SceneLoader";
import { Debug, Physics, usePlane } from "@react-three/cannon";
import Player from "./modules/Player";
import { Effects } from "./modules/Effects/Effects";
import ProductLoader from "./modules/ProductLoader";
import ReadyPlayerMe from "./ReadyPlayerMe";
import EditBanner from "../../pages/owner/store/PreviewComponents/EditBanner";

const Loader = () => {
    const { progress } = useProgress();
    return <Html center>{progress} % loaded</Html>;
};

function Plane(props) {
    const [ref] = usePlane(() => ({ mass: 0, ...props }), useRef());
    return (
        <mesh ref={ref} receiveShadow>
            <planeGeometry args={[500, 500]} />
            <meshStandardMaterial />
        </mesh>
    );
}
const App = (props) => {
    const { store } = props;
    console.log(store, "storeDetails");
    const sceneRef = useRef();
    const playerRef = useRef();
    const collisionMesh = useRef([]);

    const [avatarUrl, setAvatarUrl] = useState("");

    //   const [showBannerModal, setShowBannerModal] = useState(false);

    const handleBannerClick = (bannerInfo, editor) => {
        // console.log("handleBannerClick from app", bannerInfo);
        props.handleBannerClick(true);
        props.handleBannerInfo(bannerInfo);
    };

    const handleProductClick = (productInfo) => {
        console.log("prodInfo", productInfo);
        props.handleProductClick(true);
        props.handleProductInfo(productInfo);
    };

    if (avatarUrl === "" && props.editor == false) {
        return (
            <ReadyPlayerMe avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl} />
        );
    } else if (props.editor == true) {
        return (
            <>
                <Canvas
                    shadows
                    gl={{ antialias: false, stencil: false }}
                    // onMouseDown={handleMouseDown}
                    // onMouseUp={handleMouseUp}
                    // style={{ cursor: cursor }}
                >
                    {/* <Stats /> */}
                    <Suspense fallback={<Loader />}>
                        {/* <ambientLight intensity={0.5} /> */}
                        <Environment preset={"sunset"} background blur={0.5} />
                        <Physics>
                            <Plane rotation={[-Math.PI / 2, 0, 0]} />
                            <SceneLoader
                                handleBannerClick={handleBannerClick}
                                editor={props.editor}
                                sceneRef={sceneRef}
                                collisionMesh={collisionMesh}
                                glbPath={store?.scene?.path} //"https://vosmos-metaverse.s3.ap-south-1.amazonaws.com/files/8774505.476714_Store+7.glb"
                                scaleFactor={store?.scene?.scaleFactor}
                                position={[
                                    store?.scene?.position?.x,
                                    store?.scene?.position?.y,
                                    store?.scene?.position?.z,
                                ]}
                                lights={store?.scene?.lights}
                                images={store?.scene?.images}
                                materials={store?.scene?.materials}
                            />
                            <ProductLoader
                                handleProductClick={handleProductClick}
                                editor={props.editor}
                                collisionMesh={collisionMesh}
                                products={store?.products}
                            />
                            <Player
                                editor={props?.editor}
                                collisionMesh={collisionMesh}
                                playerRef={playerRef}
                                path="https://models.readyplayer.me/6436995defa5bcefaebab536.glb"
                                position={[
                                    store?.character?.position?.x,
                                    store?.character?.position?.y,
                                    store?.character?.position?.z,
                                ]}
                                scaleFactor="2"
                                lookAt={[0, 0, -100]}
                            />
                        </Physics>
                        <Effects />
                        <Preload all />
                    </Suspense>
                </Canvas>
            </>
        );
    } else {
        return (
            <Canvas
                shadows
                gl={{ antialias: false, stencil: false }}
                // onMouseDown={handleMouseDown}
                // onMouseUp={handleMouseUp}
                // style={{ cursor: cursor }}
            >
                {/* <Stats /> */}
                <Suspense fallback={<Loader />}>
                    {/* <ambientLight intensity={0.5} /> */}
                    <Environment preset={"sunset"} background blur={0.5} />
                    <Physics>
                        <Plane rotation={[-Math.PI / 2, 0, 0]} />
                        <SceneLoader
                            editor={props.editor}
                            sceneRef={sceneRef}
                            collisionMesh={collisionMesh}
                            glbPath={store?.scene?.path} //"https://vosmos-metaverse.s3.ap-south-1.amazonaws.com/files/8774505.476714_Store+7.glb"
                            scaleFactor={store?.scene?.scaleFactor}
                            position={[
                                store?.scene?.position?.x,
                                store?.scene?.position?.y,
                                store?.scene?.position?.z,
                            ]}
                            lights={store?.scene?.lights}
                            images={store?.scene?.images}
                            materials={store?.scene?.materials}
                        />
                        <ProductLoader
                            editor={props.editor}
                            collisionMesh={collisionMesh}
                            products={store?.products}
                        />
                        <Player
                            editor={props?.editor}
                            collisionMesh={collisionMesh}
                            playerRef={playerRef}
                            path={avatarUrl}
                            position={[
                                store?.character?.position?.x,
                                store?.character?.position?.y,
                                store?.character?.position?.z,
                            ]}
                            scaleFactor="2"
                            lookAt={[0, 0, -100]}
                        />
                    </Physics>
                    <Effects />
                    <Preload all />
                </Suspense>
            </Canvas>
        );
    }
};

export default App;
