import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import GalleryGlbModel from "../../../../components/GalleryGlbModel";
import Config from "../../../../config";
import actionTypes from "../../../../store/action/type";
import { getByPath } from "../../../../store/action/authAction";
import { useParams } from "react-router-dom";

const EditBanner = (props) => {
    console.log(props, "bannerCheck");

    // const dispatch = useDispatch();

    return (
        <>
            <Modal
                show={props.show}
                size="sm-down"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="siteBox p-3  maxWidth450"
            >
                <button
                    className="border-0 position-absolute end-0 top-0 rounded-circle p-0 d-flex align-items-center justify-content-center boxShadow zi-2 closeBtn"
                    data-bs-dismiss="modal"
                    onClick={props.onHide}
                >
                    <i className="bi bi-x"></i>
                </button>
                <Modal.Body className="siteBoxInner bg-white">
                    <h4 className="fs-15 mb-1">Update Banner</h4>
                    <p className="fs-11 text-muted">
                        Upload an image/video to update
                    </p>
                    <hr />
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <label
                                for="scene.name"
                                className="form-label fs-12"
                            >
                                Store Name
                            </label>
                            <select
                                className="form-select siteInput rounded-0 fs-12 ps-0"
                                id="bannerType"
                                name="bannerType"
                                aria-invalid="false"
                                defaultValue={"Image"}
                                // onChange={onChangeHandler}
                                required
                            >
                                <option>Select One</option>
                                <option value="Image">Image</option>
                                <option value="Video">Video</option>
                            </select>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditBanner;
