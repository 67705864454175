import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import GalleryGlbModel from '../../../../components/GalleryGlbModel'
import Config from '../../../../config'
import actionTypes from '../../../../store/action/type'
import { getByPath } from '../../../../store/action/authAction'
import { useParams } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'

const EditSocialMedia = (props) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { id } = params
  const [whatsappNumber, setWhatsappNumber] = useState()
  const [faceBook, setFaceBook] = useState()
  const [instaGram, setInstaGram] = useState()
  const [linkedIn, setLinkedIn] = useState()
  const [twitter, setTwitter] = useState()
  const [email, setEmail] = useState()
  var storeOldData = useSelector((state) => state.ownerReducer.listData)
  var current = storeOldData.filter((el) => el._id === id)
  // console.log(current, "current store");
  const update = () => {
    const values = {
      whatsappNumber: whatsappNumber
        ? whatsappNumber
        : current[0]?.whatsappNumber,
      socialMedia: {
        faceBook: faceBook ? faceBook : current[0]?.socialMedia?.faceBook,
        instaGram: instaGram ? instaGram : current[0]?.socialMedia?.instaGram,
        linkedIn: linkedIn ? linkedIn : current[0]?.socialMedia?.linkedIn,
        twitter: twitter ? twitter : current[0]?.socialMedia?.twitter,
        email: email ? email : current[0]?.socialMedia?.email,
      },
    }
    // console.log(values, "values");
    dispatch(
      getByPath(
        Config.apiOwnerPath + Config.endPoints.updateStore + '/' + id,
        values,
        actionTypes.UPDATE_STORE,
        Config.method.put
      )
    )
  }

  return (
    <>
      <Modal
        {...props}
        size='sm-down'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='siteBox p-3  maxWidth450'
      >
        <button
          className='border-0 position-absolute end-0 top-0 rounded-circle p-0 d-flex align-items-center justify-content-center boxShadow zi-2 closeBtn'
          data-bs-dismiss='modal'
          onClick={() => {
            props.onHide(false)
          }}
        >
          <i className='bi bi-x'></i>
        </button>
        <Modal.Body className='siteBoxInner bg-white'>
          <h4 className='fs-15 mb-1'>Manage Social Media Info</h4>
          <p className='fs-11 text-muted'>
            Upload Social Media Info to update.
          </p>
          <hr />
          <div className='row'>
            <div className='col-md-12 mb-2'>
              <span className='text-muted'>
                <i className='bi bi-facebook'></i>
              </span>
              <label for='facebook' className='form-label fs-12 ps-1'>
                Facebook
              </label>
              <input
                type='text'
                className='form-control siteInput rounded-0 fs-12 ps-0'
                id='facebook'
                name='facebook'
                placeholder='https://Instagram.com/kestone'
                defaultValue={current[0]?.socialMedia?.faceBook}
                onChange={(e) => setFaceBook(e.target.value)}
              />
            </div>
            <div className='col-md-12 mb-2 '>
              <span className='text-muted'>
                <i className='bi bi-instagram'></i>
              </span>
              <label for='instagram' className='form-label fs-12 ps-1'>
                Instagram
              </label>
              <input
                type='text'
                className='form-control siteInput rounded-0 fs-12 ps-0'
                id='instagram'
                name='instaGram'
                placeholder='https://Instagram.com/kestone'
                defaultValue={current[0]?.socialMedia?.instaGram}
                onChange={(e) => setInstaGram(e.target.value)}
              />
            </div>
            <div className='col-md-12 mb-2'>
              <span className='text-muted'>
                <i className='bi bi-linkedin'></i>
              </span>
              <label for='linkedin' className='form-label fs-12 ps-1'>
                LinkedIn
              </label>
              <input
                type='text'
                className='form-control siteInput rounded-0 fs-12 ps-0'
                id='linkedin'
                name='linkedin'
                placeholder='https://Instagram.com/kestone'
                defaultValue={current[0]?.socialMedia?.linkedIn}
                onChange={(e) => setLinkedIn(e.target.value)}
              />
            </div>
            <div className='col-md-12 mb-2'>
              <span className='text-muted'>
                <i className='bi bi-twitter'></i>
              </span>
              <label className='form-label fs-12 ps-1' for='twitter'>
                Twitter Profile
              </label>

              <input
                type='text'
                id='twitter'
                className='form-control siteInput rounded-0 fs-12 ps-0'
                placeholder='https://twitter.com/kestone'
                name='twitter'
                value={twitter ? twitter : current[0]?.socialMedia?.twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </div>
            <div className='col-md-12 mb-2'>
              <span className='text-muted'>
                <i className='bi bi-envelope-at-fill'></i>
              </span>
              <label for='Email' className='form-label fs-12 ps-1'>
                Email
              </label>
              <input
                type='text'
                className='form-control siteInput rounded-0 fs-12 ps-0'
                id='email_0'
                placeholder='Email '
                name='email'
                defaultValue={current[0]?.socialMedia?.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='col-md-12 mb-2'>
              <span className='text-muted'>
                <i className='bi bi-whatsapp'></i>
              </span>
              <label for='whatsApp' className='form-label fs-12 ps-1'>
                Whatsapp Number
              </label>
              <PhoneInput
                defaultCountry='IN'
                placeholder='Enter phone number'
                className='form-control inputStyle1 mt-2'
                id='mobileNumber'
                name='mobile'
                required
                // onChange={(e) => console.log(e.target.value, 'country code')}
                value={
                  whatsappNumber ? whatsappNumber : current[0]?.whatsappNumber
                }
                onChange={setWhatsappNumber}
              />
            </div>
          </div>
          <div className='mb-1 text-center'>
            <button
              className='btn siteBtn btnTeal'
              type='submit'
              onClick={update}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditSocialMedia
