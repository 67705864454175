import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Guide from "../../../components/Guide";
import EditManageSalesCharacter from "./PreviewComponents/EditManageSalesCharacter";
import EditStoreInfo from "./PreviewComponents/EditStoreInfo";
import EditSocialMedia from "./PreviewComponents/EditSocialMedia";
import preImage from "../../../assests/img/high-resolution-wallpapers-25.jpg";
import EditBanner from "./PreviewComponents/EditBanner";
import EditDisplayProduct from "./PreviewComponents/EditDisplayProduct";
import IntroTextModel from "./IntroTextModel";
import { useParams } from "react-router-dom";
import App from "../../../components/metaverse/App";
import configData from "../../../config";
import actionTypes from "../../../store/action/type";
import { getByPath } from "../../../store/action/authAction";

const PreviewStore = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    var storeOldData = useSelector((state) => state.ownerReducer.listData);
    var current = storeOldData.filter((el) => el._id === id);
    var storeDataId = useSelector((state) => state.ownerReducer.storeId);
    console.log(storeDataId, "storeDataId");
    const data = useSelector((state) => state.metaVerseReducer);
    const { storeById } = data;
    const [showGuideModal, setShowGuideModal] = useState(false);
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [showStoreInfoModal, setShowStoreInfoModal] = useState(false);
    const [showSocialModal, setShowSocialModal] = useState(false);
    const [showStoreCharacterModal, setShowStoreCharacterModal] =
        useState(false);
    const [showBannerModal, setShowBannerModal] = useState(false);
    const [bannerInfo, setBannerInfo] = useState(null);
    const [showDisplayModal, setShowDisplayModal] = useState(false);
    const [productInfo, setProductInfo] = useState(null);
    const [introTextModel, setIntroTextModel] = useState(false);

    useEffect(() => {
        dispatch(
            getByPath(
                configData.apiOwnerPath +
                    configData.endPoints.getStore +
                    "/" +
                    "643655f0bd69166a833947aa",
                {},
                actionTypes.GET_STORE_ID,
                configData.method.get
            )
        );
    }, [dispatch]);

    return (
        <>
            {/* <div className='overflow-hidden '></div> */}
            <div
                className="position-fixed start-0 top-0 p-4"
                style={{ zIndex: "999" }}
            >
                <a
                    className="btn circleBtn rounded-circle position-relative border-0 d-inline-block"
                    href="/owner/store"
                >
                    <span className="icon menu">
                        <i className="bi bi-arrow-left-short fs-18"></i>
                    </span>
                </a>
            </div>

            <div
                className="position-fixed start-0 bottom-0 p-4 jqSupPar"
                style={{ zIndex: "999" }}
            >
                <div className="jqParent">
                    {showEditMenu ? (
                        <button
                            className="btn circleBtn rounded-circle position-relative border-0 jqTrigger"
                            onClick={() => {
                                setShowEditMenu(false);
                            }}
                        >
                            <span className="icon ">
                                <i className="bi bi-x"></i>
                            </span>
                        </button>
                    ) : (
                        <>
                            <button
                                className="btn circleBtn rounded-circle position-relative border-0 jqTrigger"
                                onClick={() => {
                                    setShowEditMenu(true);
                                }}
                            >
                                <span className="icon menu">
                                    <i className="bi bi-pencil"></i>
                                </span>
                            </button>
                        </>
                    )}
                    {showEditMenu ? (
                        <>
                            <div className="siteBox maxWidth350  bottomSiteBox position-fixed p-3 zi-2 mb-50">
                                <div className="bg-white siteBoxInner">
                                    <button
                                        className="greyLink d-flex align-items-center w-100 fs-13 text-truncate mb-2 cursurPointer inClickHideJq"
                                        data-hide="editMenu"
                                        onClick={() => {
                                            setShowStoreInfoModal(true);
                                        }}
                                    >
                                        <span className="icon me-2 text-dark fs-16">
                                            <i className="bi bi-info-circle"></i>
                                        </span>{" "}
                                        Manage Store Info
                                    </button>

                                    <button
                                        className="greyLink d-flex align-items-center w-100 fs-13 text-truncate mb-2 cursurPointer inClickHideJq"
                                        data-hide="editMenu"
                                        onClick={() => {
                                            setShowStoreCharacterModal(true);
                                        }}
                                    >
                                        <span className="icon me-2 text-dark fs-16">
                                            <i className="bi bi-person-fill-gear"></i>
                                        </span>{" "}
                                        Manage Sales Character
                                    </button>

                                    <button
                                        className="greyLink d-flex align-items-center w-100 fs-13 text-truncate mb-2 cursurPointer inClickHideJq"
                                        data-hide="editMenu"
                                        onClick={() => setShowSocialModal(true)}
                                    >
                                        <span className="icon me-2 text-dark fs-16">
                                            <i className="bi bi-share-fill"></i>
                                        </span>{" "}
                                        Manage Social Media Info
                                    </button>

                                    <button
                                        className="greyLink d-flex align-items-center w-100 fs-13 text-truncate cursurPointer inClickHideJq"
                                        data-hide="editMenu"
                                        onClick={() => setIntroTextModel(true)}
                                    >
                                        <span className="icon me-2 text-dark fs-16">
                                            <i className="bi bi-play-circle"></i>
                                        </span>{" "}
                                        Manage Audio Welcome Message
                                    </button>

                                    <button
                                        className="greyLink d-flex align-items-center w-100 fs-13 text-truncate mb-2 cursurPointer inClickHideJq"
                                        data-hide="editMenu"
                                        onClick={() => {
                                            setShowBannerModal(true);
                                        }}
                                    >
                                        <span className="icon me-2 text-dark fs-16">
                                            <i className="bi bi-info-circle"></i>
                                        </span>{" "}
                                        Update Banner
                                    </button>
                                    <button
                                        className="greyLink d-flex align-items-center w-100 fs-13 text-truncate mb-2 cursurPointer inClickHideJq"
                                        data-hide="editMenu"
                                        onClick={() => {
                                            setShowDisplayModal(true);
                                        }}
                                    >
                                        <span className="icon me-2 text-dark fs-16">
                                            <i className="bi bi-info-circle"></i>
                                        </span>{" "}
                                        Update Display Product
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <div
                        className="opanBoxJq position-fixed vw-100 vh-100 start-0 end-0 top-0 bottom-0 zi-1 jqTrigger"
                        id="editMenu"
                    ></div>
                </div>

                <div className="mt-2">
                    <button
                        className="btn circleBtn rounded-circle position-relative border-0"
                        id="GuideBtn"
                        title="Help"
                        // onClick={(e) => {
                        //   handleGuide();
                        // }}
                        //onClick={setShowModal}
                        onClick={() => {
                            setShowGuideModal(true);
                        }}
                    >
                        <span className="icon menu">
                            <i className="bi bi-question"></i>
                        </span>
                        <span className="icon close">
                            <i className="bi bi-x"></i>
                        </span>
                    </button>
                </div>
            </div>

            <App
                store={storeDataId}
                editor={true}
                handleBannerClick={setShowBannerModal}
                handleBannerInfo={setBannerInfo}
                handleProductClick={setShowDisplayModal}
                handleProductInfo={setProductInfo}
            />

            <EditStoreInfo
                show={showStoreInfoModal}
                onHide={() => setShowStoreInfoModal(false)}
            />
            <EditManageSalesCharacter
                show={showStoreCharacterModal}
                onHide={() => setShowStoreCharacterModal(false)}
            />
            <EditSocialMedia
                show={showSocialModal}
                onHide={() => setShowSocialModal(false)}
            />
            <EditBanner
                bannerInfo={bannerInfo}
                show={showBannerModal}
                onHide={() => setShowBannerModal(false)}
            />
            <EditDisplayProduct
                productInfo={productInfo}
                show={showDisplayModal}
                onHide={() => setShowDisplayModal(false)}
            />
            <IntroTextModel
                id={id}
                show={introTextModel}
                onHide={() => setIntroTextModel(false)}
            />
            <Guide
                show={showGuideModal}
                onHide={() => setShowGuideModal(false)}
            />
        </>
    );
};

export default PreviewStore;
