import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import mainReducer from "./mainReducer";
import adminReducer from "./adminReducer";
import ownerReducer from "./ownerReducer";
import metaVerseReducer from "./metaVerseReducer";
const appReducer = combineReducers({
  authReducer,
  errorReducer,
  mainReducer,
  adminReducer,
  ownerReducer,
  metaVerseReducer,
});
const rootReducer = (state, action) => {
  // if (action.type === "USER_LOGGED_OUT") {
  //   state = undefined;
  // }
  return appReducer(state, action);
};
export default rootReducer;
