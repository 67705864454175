import React, { useState } from 'react'
import Background from '../../../../assests/vosmos/img/CityTop2.jpg'
import VosmosLogo from '../../../../assests/vosmos/img/logo.png'
import SignUpForm from '../signUp/SignUpForm'
// import { Form } from 'react-bootstrap'
// import LoginForm from "./LoginForm";
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getByPath } from '../../../../store/action/authAction'
import actionTypes from '../../../../store/action/type'
import Config from '../../../../config'
// import "../../../../../src/index.css";
// import "../../../../assests/vosmos/css/custom.css";
import { CustomToast } from '../../../commonFunction'
import { Check2 } from 'react-bootstrap-icons'
import 'react-toastify/dist/ReactToastify.css'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import validator from 'validator'
// import { login } from "../../../store/action/userActions";
// import { useNavigate } from "react-router-dom";
import configData from '../../../../config'
import ForgetPassword from '../forget/ForgetPassword'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const LoginNew = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [login, setLogin] = useState(true)
  const [forget, setForget] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const storeData = useSelector((state) => state?.authReducer)
  const { isAuthenticated, isLoading } = storeData
  const loginUser = () => {
    var values = { email, password }
    if (email && password) {
      if (
        validator.isStrongPassword(password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        dispatch(
          getByPath(
            Config.apiAdminPath + Config.endPoints.loginUser,
            values,
            actionTypes.LOGIN_USER,
            Config.method.post
          )
        )
      } else {
        dispatch(
          getByPath(
            Config.apiAdminPath + Config.endPoints.loginUser,
            values,
            actionTypes.LOGIN_USER,
            Config.method.post
          )
        )
        toast.info(
          <CustomToast
            icon={<Check2 size={20} color='#fff' />}
            title={'Error'}
            description={
              'Invalid Password! password must be 8-16 characters long and contain one uppercase and one lowercase character example'
            }
          />
        )
      }
    } else {
      toast.info(
        <CustomToast
          icon={<Check2 size={20} color='#fff' />}
          title={'Error'}
          description={'Invalid Inputs!'}
        />
      )
      // toast("Invalid Inputs!", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
  }
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userInfo'))
    //console.log(user, "localuser");

    if (isAuthenticated && user.token !== 'undefined') {
      navigate('/owner/dashboard')
    }
  }, [isAuthenticated, navigate])

  return (
    <>
      <Form
        style={{ display: login ? '' : 'none' }}
        // onSubmit={loginUser}
      >
        <Form.Group autoComplete='off'>
          <div className='form-floating mb-1 col-lg-8'>
            <Form.Control
              type='email'
              className='form-control inputStyle1 ps-1'
              id='emailID'
              placeholder='Enter Email ID'
              name='email'
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor='emailID' className='ps-0'>
              Enter Email ID
            </label>
          </div>
        </Form.Group>
        <Form.Group autoComplete='off'>
          <div className='form-floating mb-4 col-lg-8'>
            <Form.Control
              type='password'
              className='form-control inputStyle1 ps-1'
              id='password'
              placeholder='Enter password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor='emailID' className='ps-0'>
              Enter password
            </label>
          </div>
        </Form.Group>
        <button
          type='button'
          className='btn btn-sm btnTeal px-4 py-2 showOtp'
          onClick={loginUser}
        >
          Submit
        </button>
        <br />
        <div className='mt-2'>
          <a
            onClick={() => {
              setForget(true)
              setLogin(false)
            }}
            class='showForgetPassword mt-4 text-align-center fs-12'
          >
            Forget Password?
          </a>
        </div>
        <p className='mt-5 fs-12'>
          Don’t have an account?{' '}
          {/* <a href='{configData.route.register}' className='showSignUp'>
          </a> */}
          <Link
            onClick={() => {
              setLogin(false)
              setForget(false)
            }}
            className='showSignUp'
          >
            SIGN UP
          </Link>
        </p>
      </Form>
      {login ? '' : forget ? <ForgetPassword /> : <SignUpForm />}
    </>
  )
}

export default LoginNew
