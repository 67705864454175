//import "../src/assests/vosmos/css/sidebar.css";
import LayoutRoute from "./common/layout/LayoutRoute";
import { Provider } from "react-redux";
import store from "./store/store";
import { logoutUser } from "./store/action/authAction";
import setAuthToken from "./store/setAuthToken";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { X } from "react-bootstrap-icons";
const CloseButton = ({ closeToast }) => (
  <X size={24} className="minWidth24" onClick={closeToast}></X>
);
function App() {
  if (localStorage.userInfo) {
    setAuthToken(sessionStorage.token);
    const user = localStorage.userInfo;
    const glb = sessionStorage.getItem("glbUrl");
    const image = sessionStorage.getItem("imgURl");
    sessionStorage.setItem("modelStatus", false);
    // var storeData = JSON.parse(localStorage.getItem("storeData"));
    // console.log(storeData, "getDAta");

    // const storeData = localStorage.getItem("storeData");
    store.dispatch({
      type: "LOGIN_USER",
      payload: user,
    });
    store.dispatch({
      type: "GALLERY_GLB_URL",
      payload: glb,
    });
    store.dispatch({
      type: "GALLERY_IMAGE_URL",
      payload: image,
    });
    // store.dispatch({
    //   type: "GET_STORE",
    //   payload: storeData,
    // });
    // const currentTime = Date.now() / 1000;
    // if (user.exp < currentTime) {
    //   store.dispatch(logoutUser());
    //   window.location.href = "/";
    // }
  }
  return (
    <>
      <Provider store={store}>
        {/* <PersistGate persistor={persistor}> */}
        <ToastContainer autoClose={2000} closeButton={CloseButton} />
        <LayoutRoute />
        {/* </PersistGate>*/}
      </Provider>
    </>
  );
}

export default App;
