import {
    OrbitControls,
    Text,
    useAnimations,
    useFBX,
    useGLTF,
} from "@react-three/drei";
import { useEffect, useMemo, useState } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as THREE from "three";
import { useInput } from "./hooks/useInput";
import { useRef } from "react";
import * as CANNON from "cannon-es";
import {
    CannonBody,
    useBox,
    useConvexPolyhedron,
    useCylinder,
    useTrimesh,
} from "@react-three/cannon";
import { MeshNormalMaterial } from "three";

let walkDirection = new THREE.Vector3();
let rotateAngle = new THREE.Vector3(0, 1, 0);
let rotateQuaternion = new THREE.Quaternion();
let cameraTarget = new THREE.Vector3();

const directionOffset = ({ forward, backward, left, right }) => {
    var directionOffset = 0;

    if (backward) {
        if (left) {
            directionOffset = -Math.PI / 4; //s+a
        } else if (right) {
            directionOffset = Math.PI / 4; //s+d
        }
    } else if (forward) {
        if (left) {
            directionOffset = -Math.PI / 4 - Math.PI / 2; //w+a
        } else if (right) {
            directionOffset = Math.PI / 4 + Math.PI / 2; //w+d
        } else {
            directionOffset = Math.PI; //w
        }
    } else if (left) {
        directionOffset = -Math.PI / 2; //a
    } else if (right) {
        directionOffset = Math.PI / 2; //d
    }

    return directionOffset;
};

const Player = (props) => {
    const playerAnimationRef = useRef();
    const orbitControlRef = useRef();
    // const characterGlb.scene = useRef();
    const camera = useThree((state) => state.camera);
    const scene = useThree((state) => state.scene);

    //console.log("collisionMesh", props.collisionMesh);

    const updateCameraTarget = (moveX, moveZ) => {
        camera.position.x -= moveX;
        camera.position.z -= moveZ;

        cameraTarget.x = characterGlb.scene.position.x;
        cameraTarget.y = characterGlb.scene.position.y + 4;
        cameraTarget.z = characterGlb.scene.position.z;

        if (orbitControlRef.current)
            orbitControlRef.current.target = cameraTarget;
    };

    const updateCamera = () => {
        camera.position.x = characterGlb.scene.position.x;
        camera.position.y = characterGlb.scene.position.y + 4;
        camera.position.z = characterGlb.scene.position.z + 4;

        // update camera target
        cameraTarget.x = characterGlb.scene.position.x;
        cameraTarget.y = characterGlb.scene.position.y + 4;
        cameraTarget.z = characterGlb.scene.position.z;
        if (orbitControlRef.current)
            orbitControlRef.current.target = cameraTarget;
    };

    // const updateContainer = () => {
    //     const box = new THREE.Box3().setFromObject(characterGlb.scene);
    //     const size = box.getSize(new THREE.Vector3());
    //     const center = box.getCenter(new THREE.Vector3());

    //     container.position.copy(center);
    //     container.scale.copy(size);
    //     container.visible = true;
    // };

    const checkCollision = () => {
        var originPoint = characterGlb.scene.position.clone();
        originPoint.y = 2;

        var downPoint = characterGlb.scene.position.clone();
        downPoint.y = 0.1;

        if (characterGlb.scene) {
            var localVertex = characterGlb.scene.position.clone();

            var globalVertex = localVertex.applyMatrix4(
                characterGlb.scene.matrix
            );

            var directionVector = new THREE.Vector3(
                -walkDirection.x,
                walkDirection.y,
                -walkDirection.z
            );

            //var rayBody = new THREE.Raycaster(originPoint, directionVector);
            var rayfoot = new THREE.Raycaster(downPoint, directionVector);
            // scene.add(
            //     new THREE.ArrowHelper(
            //         rayBody.ray.direction,
            //         rayBody.ray.origin,
            //         300,
            //         0xff0000
            //     )
            // );

            var collisionResults = rayfoot.intersectObjects(
                props.collisionMesh?.current
            );
            //var bodyCollisionResult = rayBody.intersectObjects(props.collisionMesh?.current);

            if (collisionResults) {
                if (
                    collisionResults.length > 0 &&
                    collisionResults[0].distance < 2
                ) {
                    console.log("collision ", collisionResults[0])
                    return true;
                } else {
                    return false;
                    // if(bodyCollisionResult){
                    //     if (
                    //         bodyCollisionResult.length > 0 &&
                    //         bodyCollisionResult[0].distance < 4
                    //     ) {
                    //         return true;
                    //     }
                    //     else{
                    //         return false;
                    //     }
                    // }
                }
            }

        } else {
            return false;
        }
    };

    const { forward, backward, left, right, jump, shift } = useInput();

    const dracoLoader = new DRACOLoader();
    const characterGlb = useLoader(GLTFLoader, props.path, (loader) => {
        dracoLoader.setDecoderPath("../libs/draco/");
        loader.setDRACOLoader(dracoLoader);
    });
    //console.log(characterGlb.nodes.Wolf3D_Avatar);

    // const container = new THREE.Mesh(
    //     new THREE.BoxGeometry(1, 1, 1),
    //     new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true })
    // );
    //updateContainer();

    const fbx = useGLTF(
        "https://cdn.vosmos.live/metaverse/assets/rpm-model/myModel.glb"
    );

    const { actions } = useAnimations(fbx.animations, playerAnimationRef);

    const currentAction = useRef("");

    useEffect(() => {
        // do stuff here...
        characterGlb.scene.traverse((node) => {
            if (!node.name.includes("mixamorig"))
                node.name = "mixamorig" + node.name;
            if (node.isMesh) {
                node.castShadow = true;
                node.receiveShadow = true;
            }
        });
        characterGlb.scene.scale.setScalar(props.scaleFactor);
        // characterGlb.scene.position.set(
        //     props.position[0],
        //     props.position[1],
        //     props.position[2]
        // );
        characterGlb.scene.lookAt(
            props.lookAt[0],
            props.lookAt[1],
            props.lookAt[2]
        );
        // updateContainer();
        // move camera
        updateCamera();
    }, []);

    useEffect(() => {
        let action = "";
        if (forward || backward || left || right) {
            action = "walking";
            if (shift) {
                action = "running";
            }
        } else if (jump) {
            action = "jump";
        } else {
            action = "idle";
        }

        if (currentAction.current != action) {
            const nextActionToPlay = actions[action];
            const current = actions[currentAction.current];
            current?.fadeOut(0.2);
            nextActionToPlay?.reset().fadeIn(0.2).play();
            currentAction.current = action;
        }
    }, [forward, backward, left, right, jump, shift]);

    useFrame((state, delta) => {
        if (
            currentAction.current == "running" ||
            currentAction.current == "walking"
        ) {
            let angleYCameraDirection = Math.atan2(
                camera.position.x - characterGlb.scene.position.x,
                camera.position.z - characterGlb.scene.position.z
            );

            let newDirectionOffset = directionOffset({
                forward,
                backward,
                left,
                right,
            });

            rotateQuaternion.setFromAxisAngle(
                rotateAngle,
                angleYCameraDirection + newDirectionOffset
            );
            characterGlb.scene.quaternion.rotateTowards(rotateQuaternion, 0.2);

            camera.getWorldDirection(walkDirection);
            walkDirection.y = 0;
            walkDirection.normalize();
            walkDirection.applyAxisAngle(rotateAngle, newDirectionOffset);

            const velocity = currentAction.current == "running" ? 10 : 5;

            const moveX = walkDirection.x * velocity * delta;
            const moveZ = walkDirection.z * velocity * delta;

            if (!checkCollision()) {
                characterGlb.scene.position.x -= moveX;
                characterGlb.scene.position.z -= moveZ;
                updateCameraTarget(moveX, moveZ);
            }
        }
    });
    // const [refb, api] = useBox(() => ({
    //     mass: 1,
    //     position: props.position,
    // }));

    return (
        <>
            <OrbitControls
                enableDamping={true}
                enableZoom={false}
                minDistance={8}
                maxDistance={8}
                enablePan={false}
                maxPolarAngle={Math.PI / 2 + 0.5}
                ref={orbitControlRef}
            />
            <primitive
                position={props.position}
                object={characterGlb.scene}
                ref={playerAnimationRef}
            />
            {/* <mesh ref={refb}>
                <boxGeometry />
                <meshStandardMaterial />
               
            </mesh> */}
        </>
    );
};

export default Player;
