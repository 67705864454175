import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomToast } from "../../commonFunction";
import { Check2 } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import Config from "../../../config";

const VerifyUser = () => {
  const { id } = useParams();
  const [status, setStatus] = useState();
  const [msg, setMsg] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .put(Config.apiBasePath + Config.endPoints.updateUser + "/" + id, {
        isActive: true,
      })
      .then((response) => {
        // console.log(response.data, "this is the response!!!");
        setStatus(response.data.status);
        setMsg(response.data.status);
      });
    if (status === 200) {
      toast.success(
        <CustomToast
          icon={<Check2 size={20} color="#fff" />}
          title={"User Succesfully Verified"}
          description={""}
        />
      );
      navigate("/");
    }
  }, [status]);
  return <div></div>;
};

export default VerifyUser;
