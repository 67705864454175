import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const inititalState = {};
const middlewares = [thunk];
if (process.env.NODE_ENV === "development") {
  // const { logger } = require("redux-logger");
  // middlewares.push(logger);
}

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  //rootReducer,
  persistedReducer,
  inititalState,
  compose(
    applyMiddleware(...middlewares)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
//const persistor = persistStore(store);
export const persistor = persistStore(store);
export default store;
