import React from "react";

const Loader = () => {
  return (
    // <div className="position-fixed w100 loader">
    //   <div className="d-flex  w100 vh100">
    //     <div className="loaderNew">
    //       <div class="spinner-border" role="status">
    //         <span class="visually-hidden">Loading...</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div class="lading_wr prelaoder">
      <div class="obj obj1">
        <div class="obj-inner">
          <div class="moveCircle"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
