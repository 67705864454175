import React, { useEffect, useState } from "react";
import { GLTFLoader } from "three-stdlib";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";




const Product = (props) => {
    // const [hovered, setHovered] = useState(false);

    const { prod, collisionMesh } = props;
    var product = new THREE.Group();
    product.userData = { editor: props.editor };
    var isGlb = false;

    const handleProductClick = (e) => {
        console.log("clicked", e.eventObject.userData.editor);
        props.handleProductClick(prod);
    };

    if (prod.productFile.includes(".glb")) {
        isGlb = true;
        const loader = new GLTFLoader();
        loader.load(prod.productFile, (gltf) => {
            gltf.scene.scale.set(
                prod.scaleFactor,
                prod.scaleFactor,
                prod.scaleFactor
            );

            product.add(gltf.scene);

            product.position.set(
                prod.position.x,
                prod.position.y,
                prod.position.z
            );

            //_glbProducts.push(group);
            // collisionMesh.current.push(product);
        });
    } else {
        isGlb = false;
        let texture = new THREE.TextureLoader().load(prod.productFile);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        let img = new THREE.MeshBasicMaterial({
            //CHANGED to MeshBasicMaterial
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
            toneMapped: false,
        });
        // img.map.needsUpdate = true;
        let im = new Image();

        im.onload = function () {
            // alert(this.width + 'x' + this.height);
            let geo = new THREE.PlaneBufferGeometry(
                (this.width / 100) * prod.scale.width,
                (this.height / 100) * prod.scale.width
            );
            let p = new THREE.Mesh(geo, img);

            product.add(p);
            p.position.set(0, ((this.height / 100) * prod.scale.width) / 2, 0);
            product.position.set(
                prod.position.x,
                prod.position.y,
                prod.position.z
            );
            // collisionMesh.current.push(p);
            // scene.add(group);
            // _renderedProducts.push(group);
        };
        console.log("hello");
        im.src =  prod.productFile;//"https://cdn.vosmos.live/metaverse/assets/imgs/product.png";
    }
    useFrame((state) => {
        if (isGlb) {
            product.rotation.y += 0.01;
        } else {
            product.rotation.x = state.camera.rotation.x;
            product.rotation.y = state.camera.rotation.y;
            product.rotation.z = state.camera.rotation.z;
        }
    });

    return (
        <primitive
            key={props.key}
            object={product}
            onClick={handleProductClick}
            // onPointerOver={() => setHovered(true)}
            // onPointerOut={() => setHovered(false)}
        />
    );
};

const ProductLoader = (props) => {
    const { collisionMesh, products } = props;
    console.log(props, "product loader");

    return (
        <>
            {products?.map((product) => (
                <Product
                    handleProductClick = {props.handleProductClick}
                    editor={props.editor}
                    key={product.id}
                    prod={product}
                    collisionMesh={collisionMesh}
                />
            ))}
        </>
    );
};

export default ProductLoader;
