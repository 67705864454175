import actionTypes from "../action/type";
const initialState = {
  isLoading: false,
  //   isAuthenticated: false,
  store: [],
  activeStore: [],
  listData: [],
  product: [],
  poll: [],
  feedback: [],
  notification: [],
  glb_Url: {},
  img_Url: {},
  gallery: [],
  primaryGallery: [],
  imageGallery: [],
  pollId: {},
  feedbackId: {},
  notificationId: {},
  productId: {},
  salesCharacter: {},
  allCategory: [],
  storeId: {},
};

function ownerReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_STORE:
      return {
        ...state,
        store: action.payload,
        listData: action.payload,
      };
    case actionTypes.GET_ACTIVE_STORE:
      return {
        ...state,
        activeStore: action.payload,
      };
    case actionTypes.UPDATE_STORE:
      return {
        ...state,
      };
    case actionTypes.ADD_NEW_STORE:
      return {
        ...state,
        // storeData: action.payload,
      };
    case actionTypes.DELETE_STORE:
      return {
        ...state,
      };
    case actionTypes.FILTER_DATA:
      // console.log(action.payload, "action.payload");
      return {
        ...state,
        listData: action.payload,
      };

    case actionTypes.GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
        listData: action.payload,
      };
    case actionTypes.GET_POLL:
      return {
        ...state,
        poll: action.payload,
        listData: action.payload,
      };

    case actionTypes.GET_POLL_ID:
      return {
        ...state,
        pollId: action.payload,
        // listData: action.payload,
      };
    case actionTypes.UPDATE_POLL:
      return {
        ...state,
      };
    case actionTypes.GET_STORE_ID:
      return {
        ...state,
        storeId: action.payload,
      };
    case actionTypes.GET_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,
        listData: action.payload,
      };
    case actionTypes.GET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
        listData: action.payload,
      };
    case actionTypes.ADD_NEW_PRODUCT:
      return {
        ...state,
      };
    case actionTypes.UPDATE_PRODUCT:
      return {
        ...state,
      };
    // case actionTypes.ADD_GALLERY_GLB:
    //   return {
    //     ...state,
    //     //  glb_Url: action.payload,
    //   };
    case actionTypes.GALLERY_GLB_URL:
      return {
        ...state,
        glb_Url: action.payload,
      };
    case actionTypes.GALLERY_IMAGE_URL:
      return {
        ...state,
        img_Url: action.payload,
      };
    case actionTypes.ADD_GALLERY_IMAGE:
      return {
        ...state,
        // img_Url: action.payload,
      };
    case actionTypes.GET_GALLERY:
      return {
        ...state,
        gallery: action.payload,
      };
    case actionTypes.GET_PRIMARY_GALLERY:
      return {
        ...state,
        primaryGallery: action.payload,
      };
    case actionTypes.ADD_NEW_FEEDBACK:
      return {
        ...state,
      };
    case actionTypes.GET_FEEDBACK_ID:
      return {
        ...state,
        feedbackId: action.payload,
        // listData: action.payload,
      };
    case actionTypes.ADD_NEW_NOTIFICATION:
      return {
        ...state,
      };
    case actionTypes.ADD_NEW_POLL:
      return {
        ...state,
      };

    case actionTypes.GET_NOTIFICATION_ID:
      return {
        ...state,
        notificationId: action.payload,
        listData: action.payload,
      };

    case actionTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
      };
    case actionTypes.UPDATE_FEEDBACK:
      return {
        ...state,
      };
    case actionTypes.GET_PRODUCT_ID:
      return {
        ...state,
        productId: action.payload,
        // listData: action.payload,
      };
    case actionTypes.GET_IMAGE_GALLERY:
      return {
        ...state,
        imageGallery: action.payload,
      };
    case actionTypes.GET_SALES_CHARACTER:
      return {
        ...state,
        salesCharacter: action.payload,
      };
    case actionTypes.UPDATE_CHARACTER:
      return {
        ...state,
      };
    case actionTypes.ALL_CATEGORY:
      return {
        ...state,
        allCategory: action.payload,
      };

    default:
      return state;
  }
}
export default ownerReducer;
