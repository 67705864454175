import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import configData from '../../config'
import Loader from '../../components/Loader'
import PreviewStore from '../../pages/owner/store/PreviewStore'
import ChangePassword from '../public/auth/changePassword/ChangePassword'
import VerifyUser from '../public/auth/VerifyUser'

//Lazy Loading

const Main = lazy(() => import('../public/auth/login/Main'))
const SignUp = lazy(() => import('../public/auth/signUp/SignUp'))
const Home = lazy(() => import('../../pages/metaverse/Home'))
const DashBoard = lazy(() => import('../../pages/admin/DashBoard'))
const AddStoreTemplate = lazy(() =>
  import('../../pages/admin/storeTemplate/AddStoreTemplate')
)
const ManageStoreTemplate = lazy(() =>
  import('../../pages/admin/storeTemplate/ManageStoreTemplate')
)
const AddNewStore = lazy(() => import('../../pages/owner/store/AddNewStore'))
const OwnerMainList = lazy(() => import('../../pages/owner/main/OwnerMainList'))
const PageNotFound = lazy(() => import('../../components/PageNotFound'))

const LayoutRoute = () => {
  const { id } = useParams()
  console.log(id, 'id')
  const userData = useSelector((state) => state.authReducer)
  //const { isLoading1, users } = userData;
  let user = JSON.parse(localStorage.getItem('userInfo'))
  //console.log(isLoading1, "mikiRes");

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* Common Routes Start */}
            <Route path={configData.route.basePath} element={<Main />} exact />
            <Route
              path={configData.route.register}
              element={<SignUp />}
              exact
            />
            {/*  Common Routes End*/}
            {user ? (
              <>
                <Route
                  path={configData.route.dashboard}
                  element={<DashBoard />}
                  exact
                />
                <Route
                  path={configData.route.dashboard}
                  element={<DashBoard />}
                  exact
                />

                <Route
                  path={configData.endPoints.addNewTemplate}
                  element={<AddStoreTemplate />}
                  exact
                />
                <Route
                  path={configData.endPoints.manageTemplate}
                  element={<ManageStoreTemplate />}
                  exact
                />
                {/* {users.roleId === 1 && <></>}
        {users.roleId === 2 && ( */}
                <>
                  <Route
                    path={configData.route.ownerDashboard}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.manageStore}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.addNewStore}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.manageProducts}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.poll}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.addNewPoll}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path='owner/updatePoll/:id'
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path='owner/updateProduct/:id'
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.addNewProduct}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.getAllfeedBack}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.addNewFeedback}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.getAllNotification}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path='owner/updateFeedBack/:id'
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path={configData.route.addNewNotification}
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path='owner/addNewNotification/:id'
                    element={<OwnerMainList />}
                    exact
                  />
                  <Route
                    path='owner/manageStore/:id'
                    element={<PreviewStore id={id} />}
                    exact
                  />
                </>
                {/* )} */}
              </>
            ) : (
              <Route
                path={configData.route.basePath}
                element={<Main />}
                exact
              />
            )}
            {/* metaverse Route */}
            <Route path={configData.route.metaverse} element={<Home />} exact />
            <Route path='*' element={<PageNotFound />} />
            <Route
              path='changePassword/:id'
              element={<ChangePassword />}
              exact
            />
            <Route path='verifyUser/:id' element={<VerifyUser />} exact />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
    //   )}
    // </>
  )
}

export default LayoutRoute
