import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import GalleryGlbModel from '../../../../components/GalleryGlbModel'
import Config from '../../../../config'
import actionTypes from '../../../../store/action/type'
import { getByPath } from '../../../../store/action/authAction'
import { useParams } from 'react-router-dom'

const EditStoreInfo = (props) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { id } = params
  const [modalShowDelete, setModalShowDelete] = useState(false)

  const galleryDataImage = useSelector(
    (state) => state.ownerReducer.imageGallery
  )
  const [addImage, setAddImage] = useState([])
  const [name, setName] = useState()
  var storeOldData = useSelector((state) => state.ownerReducer.listData)
  var current = storeOldData.filter((el) => el._id === id)
  // console.log(current, 'current store')
  const update = () => {
    const values = {
      name: name ? name : current[0].name,
      // facebook: facebook,
      thumbnail:
        addImage.length > 0
          ? addImage[addImage.length - 1]
          : current[0].thumbnail,
    }
    console.log(values, 'values')
    dispatch(
      getByPath(
        Config.apiOwnerPath + Config.endPoints.updateStore + '/' + id,
        values,
        actionTypes.UPDATE_STORE,
        Config.method.put
      )
    )
    props.onHide(false)
    // toast.success("successfully update", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    setTimeout(() => window.location.reload('/'), 1500)
    //window.location.reload(true);
    //navigate("/owner/store");
  }
  const handleImage = (e, fileType, action) => {
    // console.log(fileType, "fileType");
    setModalShowDelete(true)
    dispatch(
      getByPath(
        Config.apiOwnerPath +
          Config.endPoints.gallery +
          '/' +
          fileType +
          '/' +
          673 +
          '/' +
          327,
        {},
        actionTypes.GET_GALLERY,
        Config.method.get
      )
    )
  }

  useEffect(() => {
    dispatch(
      getByPath(
        Config.apiOwnerPath + Config.endPoints.manageStores,
        {},
        actionTypes.GET_STORE,
        Config.method.get
      )
    )
    //setPosts(storeOldData);
  }, [dispatch])

  return (
    <>
      <Modal
        {...props}
        size='sm-down'
        aria-labelledby='contained-modal-title-vcenter'
        // rigth
        contentClassName='siteBox p-3  maxWidth450'
        //className="modal-dialog modal-dialog-centered me-5 maxWidth450"
      >
        <button
          className='border-0 position-absolute end-0 top-0 rounded-circle p-0 d-flex align-items-center justify-content-center boxShadow zi-2 closeBtn'
          data-bs-dismiss='modal'
          onClick={() => {
            props.onHide(false)
          }}
        >
          <i className='bi bi-x'></i>
        </button>
        <Modal.Body className='siteBoxInner bg-white'>
          <h4 className='fs-15 mb-1'>Manage Store Info</h4>
          <p className='fs-11 text-muted'>
            Update your store information here.
          </p>
          <hr />

          <div className='row'>
            <div className='col-md-12 mb-2'>
              <label for='scene.name' className='form-label fs-12'>
                Store Name
              </label>
              <input
                type='text'
                className='form-control siteInput rounded-0 fs-12 ps-0'
                name='scene.name'
                placeholder='Store Name'
                defaultValue={current[0].name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className='col-md-12 mb-2'>
            <label for='url' className='form-label fs-12'>
              Thumbnail
              <span className='position-relative'>
                <span
                  className='customToolTip position-absolute fs-12 ln-21 text-center'
                  data-bs-toggle='tooltip'
                  data-bs-placement='bottom'
                  title='You can change store thumbnail here.'
                >
                  ?
                </span>
              </span>
            </label>
          </div>

          <div className='text-center mb-2 position-relative active'>
            <button
              className='btn circleBtn rounded-circle position-relative border-0 position-absolute end-0 top-0'
              type='button'
              onClick={(e) => handleImage(e, 'logos', 'get')}
            >
              <i className='bi bi-pencil text-white'></i>
            </button>
            <label
              for='image_url'
              onclick="openGallery('logos','undefined','undefined', 'image')"
            >
              <img
                src={
                  addImage.length > 0
                    ? addImage[addImage.length - 1]
                    : current[0].thumbnail
                }
                id='image_src'
              />
            </label>
          </div>

          <div className='mb-1 text-center'>
            <button
              className='btn siteBtn btnTeal'
              type='submit'
              onClick={update}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <GalleryGlbModel
        show={modalShowDelete}
        type='logos'
        galleryData={galleryDataImage}
        valueGallery={setAddImage}
        valImage={addImage}
        onHide={() => setModalShowDelete(false)}
      />
    </>
  )
}

export default EditStoreInfo
