import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import actionTypes from '../store/action/type'
import { getByPath } from '../store/action/authAction'
import Config from '../config'

const Model = (props) => {
  const { saveDraft, saveStore, onHide, formData, type, productGalleryHide } =
    props
  // console.log(props, "pro");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const nav = () => {
    window.location.reload()
  }
  const confirmHandler = (path, type, currentstatus, action, methodType) => {
    console.log({ path, type, currentstatus, action, methodType }, 'modeldata')
    if (type === 'toggle') {
      dispatch(
        getByPath(
          path,
          {
            isActive: !currentstatus,
          },
          actionTypes.action,
          methodType
        )
      )
      setTimeout(() => window.location.reload(), 1000)
      // setTimeout(() => window.location.reload(), 1000);
    } else if (type === 'delete') {
      // console.log("coming in delete");
      dispatch(getByPath(path, {}, action, methodType))
      onHide()
      setTimeout(() => window.location.reload(), 1000)
    } else if (type === 'update') {
      // console.log("coming in update");
      dispatch(getByPath(path, {}, action, methodType))
      setTimeout(() => window.location.reload(), 1000)
    } else if (type === 'BgRemove') {
      console.log('working')
      dispatch(getByPath(path, formData, action, methodType))
      productGalleryHide()
    }
    onHide()
  }

  return (
    <Modal
      {...props}
      size='sm-down'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      contentClassName='siteBox p-3'
    >
      <Modal.Body className='siteBoxInner bg-white'>
        <div className='text-center m-5'>
          <div className='w-100 display-4 mb-2 yellowColor'>
            <i className='bi bi-exclamation-diamond'></i>
          </div>
          <div className='h3 fw-400 darkGreyColor'>{props.title}</div>
          <div className='fs-18 fw-300'>{props?.description}</div>
          <div className='mt-4'>
            <button
              className='btn siteBtn fs-14 btnTealOutline me-3'
              data-bs-dismiss='modal'
              onClick={() =>
                type === 'saveStore'
                  ? saveDraft()
                  : type === 'BgRemove'
                  ? () => {
                      props.onHide(false)
                    }
                  : confirmHandler(
                      props.path,
                      props.type,
                      props.currentstatus,
                      props.action,
                      props.methodtype
                    )
              }
            >
              {type === 'saveStore'
                ? 'Save as Draft'
                : type === 'BgRemove'
                ? 'Yes'
                : 'Confirm'}
            </button>
            <button
              className='btn siteBtn fs-14 btnTeal'
              data-bs-dismiss='modal'
              onClick={
                type === 'saveStore'
                  ? () => saveStore()
                  : type === 'BgRemove'
                  ? () => {
                      confirmHandler(
                        props.path,
                        props.type,
                        false,
                        props.action,
                        props.methodtype
                      )
                      // setBgRemove(false)
                      // props.onHide(false)
                    }
                  : () => {
                      props.onHide(false)
                      nav()
                    }
              }
            >
              {type === 'saveStore'
                ? 'Proceed'
                : type === 'BgRemove'
                ? 'No'
                : 'Cancel'}
            </button>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          className=" btn siteBtn fs-14 btnTealOutline me-3"
          // onClick={update('','status')}
        >
          Close
        </Button>

        <Button className=" btn siteBtn fs-14 btnTeal" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default Model
