import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getByPath } from "../../../../store/action/authAction";
import actionTypes from "../../../../store/action/type";
import Config from "../../../../config";
import axios from "axios";
// import { toast } from 'react-toastify'
import { CustomToast } from "../../../commonFunction";
import { Check2 } from "react-bootstrap-icons";
import "react-toastify/dist/ReactToastify.css";
// import "../../../../../src/index.css";
// import "../../../../assests/vosmos/css/custom.css";
import LoginNew from "../login/LoginNew";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import validator from "validator";

const SignUpForm = () => {
  const [value, setValue] = useState();
  const [email, setEmailID] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [roleId, setroleId] = useState("");
  const [phone, setphone] = useState("");
  const [gender, setGender] = useState();
  const [message, setMessage] = useState(null);
  const [register, setRegister] = useState(true);
  const [login, setLogin] = useState(false);

  const location = useNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    // register(email, "2", firstName, lastName, phone, password);
    // location("/");
    // console.log(value, "values outside");
    if (email && firstName) {
      if (
        validator.isStrongPassword(password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        if ((value && value.length > 9) || value.length < 10) {
          if (value?.startsWith("+")) {
            var values = {
              email: email,
              roleId: "2",
              firstName: firstName,
              lastName: lastName,
              phone: value,
              password: password,
              link: window.location + "verifyUser",
            };
            // console.log(value, "values inside");
            axios
              .post(Config.apiBasePath + Config.endPoints.registerUser, values)
              .then((response) => {
                dispatch({
                  type: actionTypes.REGISTER_USER,
                });
                setRegister(false);
                localStorage.setItem("newRegister", true);
                localStorage.setItem("newRegisterProduct", true);
                toast.success(
                  <CustomToast
                    icon={<Check2 size={20} color="#fff" />}
                    title={"Success"}
                    description={response.data.msg}
                  />
                );
              })
              .catch((err) => {
                toast.error(
                  <CustomToast
                    icon={<Check2 size={20} color="#fff" />}
                    title={"Error"}
                    description={err.response.data.msg}
                  />
                );
              });
          } else {
            toast.info(
              <CustomToast
                icon={<Check2 size={20} color="#fff" />}
                title={"Error"}
                description={"Invalid Phone! Country Code Invalid"}
              />
            );
          }
        } else {
          toast.info(
            <CustomToast
              icon={<Check2 size={20} color="#fff" />}
              title={"Error"}
              description={
                "Invalid Phone! Phone number should contain only 9 only digits!"
              }
            />
          );
        }
      } else {
        toast.info(
          <CustomToast
            icon={<Check2 size={20} color="#fff" />}
            title={"Error"}
            description={
              "Invalid Password! password must be 8-16 characters long and contain one uppercase and one lowercase character example"
            }
          />
        );
      }
    } else {
      toast.info(
        <CustomToast
          icon={<Check2 size={20} color="#fff" />}
          title={"Error"}
          description={"Invalid Inputs!"}
        />
      );
    }
  };
  return (
    <>
      <form
        id="registerForm"
        style={{
          display: register ? "" : "none",
        }}
        autoComplete="off"
      >
        <input
          autoComplete="false"
          name="hidden"
          type="text"
          style={{ display: `none` }}
        />
        <input type="text" id="ageGroupInput" name="ageGroup" hidden />
        <div className="col-lg-8 adjForm">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control inputStyle1 ps-1"
              placeholder="FirstName"
              required={true}
              name="firstName"
              onChange={(e) => setfirstName(e.target.value)}
            />
            <label htmlFor="firstName" className="ps-0">
              First Name
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control inputStyle1 ps-1"
              placeholder="Last Name"
              required={true}
              name="lastName"
              onChange={(e) => setlastName(e.target.value)}
            />
            <label htmlFor="lastName" className="ps-0">
              Last Name
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control inputStyle1 ps-1"
              placeholder="Email"
              required={true}
              name="email"
              onChange={(e) => setEmailID(e.target.value)}
            />
            <label htmlFor="email" className="ps-0">
              Email ID
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control inputStyle1 ps-1"
              placeholder="password"
              required={true}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password" className="ps-0">
              Enter Password
            </label>
          </div>
          <div className="form-floating mb-3 d-block text-dark mt-4">
            {/*  <input
              type='text'
              className='form-control inputStyle1 mt-2'
              id='mobileNumber'
              name='mobile'
              required
              onChange={(e) => setphone(e.target.value)}
            /> */}

            <PhoneInput
              defaultCountry="IN"
              placeholder="Enter phone number"
              className="form-control inputStyle1 mt-2"
              id="mobileNumber"
              name="mobile"
              required={true}
              value={value}
              onChange={setValue}
            />

            <label htmlFor="mobileNumber" className="ps-0 adjLabPh ">
              Mobile Number
            </label>
          </div>

          <div className="mb-3">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="inlineCheckbox1"
                name="gender"
                value="1"
                required={true}
                onChange={(e) => setGender(e.target.value)}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox1">
                Male
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="inlineCheckbox2"
                name="gender"
                value="2"
                onChange={(e) => setGender(e.target.value)}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox2">
                Female
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="inlineCheckbox3"
                name="gender"
                value="3"
                onChange={(e) => setGender(e.target.value)}
              />
              <label className="form-check-label" htmlFor="inlineCheckbox3">
                Others
              </label>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox4"
                name="terms"
                required={true}
              />
              <label
                className="form-check-label textTeal"
                htmlFor="inlineCheckbox4"
              >
                I accept{" "}
                <a
                  href="https://kestoneglobal.com/terms-of-service/"
                  target="_blank"
                  className="textTeal"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </label>
            </div>
          </div>
        </div>

        <button
          className="btn btn-sm btnTeal px-4 py-2"
          type="button"
          onClick={submitHandler}
        >
          Sign Up
        </button>
        <p className="mt-5 fs-12">
          Already have an account?{" "}
          <Link
            to="/"
            onClick={() => {
              setRegister(false);
              setLogin(true);
            }}
            className="showSignIn"
          >
            SIGN IN
          </Link>
        </p>
      </form>
      {/* {register ? '' : <LoginNew />} */}
      {register ? (
        ""
      ) : login ? (
        <LoginNew />
      ) : (
        <form autoComplete="off">
          <div className="form-floating mb-1 col-lg-8">
            <h4>Registration Successful</h4>
            <p className="fs-12 mt-4">
              Your registration has been successful. Please check you email
              inbox to activate your account.
            </p>
          </div>
        </form>
      )}
    </>
  );
};

export default SignUpForm;
