import { React, useEffect, useState } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useBox } from "@react-three/cannon";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as THREE from "three";
import { BakeShadows, Environment } from "@react-three/drei";
import EditBanner from "../../../pages/owner/store/PreviewComponents/EditBanner";

function ColliderMesh({ position, id }) {
  console.log(id);
  const [ref] = useBox(() => ({
    type: "Box",
    position: position,
    args: [1, 1, 1],
    userData: {
      isCollider: true,
    },
  }));

  return <mesh ref={ref} key={id} />;
}

const Lights = (props) => {
  var l = props.light;
  switch (l.type) {
    //Create cases for Three js Lights Here
    case "RectAreaLight":
      return (
        <rectAreaLight
          color={l.data.color}
          intensity={l.data.intensity}
          args={[100, 100]}
          position={[l.data.position.x, l.data.position.y, l.data.position.z]}
          lookAt={[l.data.position.x, l.data.position.y, l.data.position.z]}
        ></rectAreaLight>
      );

    case "PointLight":
      if (l.data.enableShadow)
        return (
          <pointLight
            color={l.data.color}
            intensity={l.data.intensity}
            position={[l.data.position.x, l.data.position.y, l.data.position.z]}
            shadow={l.data.enableShadow}
            shadow-mapSize={[
              l.data.shadow.mapSize.height,
              l.data.shadow.mapSize.width,
            ]}
          >
            <orthographicCamera
              attach="shadow-camera"
              args={[
                l.data.shadow.camera.left,
                l.data.shadow.camera.right,
                l.data.shadow.camera.top,
                l.data.shadow.camera.bottom,
              ]}
            />
          </pointLight>
        );
      else
        return (
          <pointLight
            color={l.data.color}
            intensity={l.data.intensity}
            position={[l.data.position.x, l.data.position.y, l.data.position.z]}
            shadow={l.data.enableShadow}
          ></pointLight>
        );

    case "DirectionalLight":
      if (l.data.enableShadow)
        return (
          <directionalLight
            color={l.data.color}
            intensity={l.data.intensity}
            position={[l.data.position.x, l.data.position.y, l.data.position.z]}
            castShadow={l.data.enableShadow}
            shadow-mapSize={[
              l.data.shadow.mapSize.height,
              l.data.shadow.mapSize.width,
            ]}
          >
            <orthographicCamera
              attach="shadow-camera"
              args={[
                l.data.shadow.camera.left,
                l.data.shadow.camera.right,
                l.data.shadow.camera.top,
                l.data.shadow.camera.bottom,
              ]}
            />
          </directionalLight>
        );
      else
        return (
          <directionalLight
            color={l.data.color}
            intensity={l.data.intensity}
            position={[l.data.position.x, l.data.position.y, l.data.position.z]}
            castShadow={l.data.enableShadow}
          ></directionalLight>
        );

    default:
      break;
  }
};

const SceneLoader = (props) => {
  const meshRef = props.sceneRef;

  //const collisionMesh = props.collisionMesh.current;
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  const width = window.innerWidth;
  const height = window.innerHeight;
  const { camera, scene } = useThree((state) => state);

  const handleClick = (e) => {
    mouse.set((e.clientX / width) * 2 - 1, -(e.clientY / height) * 2 + 1);
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children, true);
    // console.log("Mesh clicked!",intersects[0].object.name);
    const banner = props.images.find(
      (o) => o.objName === intersects[0].object.name
    );
    if (banner) {
      console.log("BannerClick inside scene Loader");
      props.handleBannerClick(banner, props.editor);
    }
  };

  useEffect(() => {
    glb.scene.traverse((node) => {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
        props.collisionMesh.current.push(node);
        // node.material.roughness = 0;
        // node.material.metalness = 0;
        // node.material.needsUpdate = true;
      }
      if (props.materials && props.materials.length > 0) {
        props.materials.forEach((m) => {
          if (node.isMesh) {
            if (node.material.name == m.name) {
              console.log(node.material.name);
              if (m.type == "emmisiveMaterial") {
                node.material = new THREE.MeshLambertMaterial({
                  emissive: new THREE.Color(m.color),
                  emissiveIntensity: m.intensity,
                  name: m.name,
                });
                node.material.opacity = m.opacity;
              } else if (m.type == "blinnMaterial") {
                node.castShadow = false;
                node.receiveShadow = false;
              }
            }
          }
        });
      }
    });

    if (props.images && props.images.length > 0) {
      props.images.forEach((i) => {
        const mesh = meshRef.current.getObjectByName(i.objName);

        if (mesh) {
          if (i.url) {
            let imgtexture = new THREE.TextureLoader().load(i.url);
            imgtexture.flipY = false;
            imgtexture.minFilter = THREE.LinearFilter;
            imgtexture.magFilter = THREE.LinearFilter;
            mesh.material = new THREE.MeshBasicMaterial({
              map: imgtexture,
              side: THREE.FrontSide,
              toneMapped: true,
            });
          }
          // if (i.click) {
          //     mesh.onClick = handleClick();
          // }
        } else {
          console.log(`Mesh ${i.objName} not found in GLTF file`);
        }
      });
    }
  }, []);

  const dracoLoader = new DRACOLoader();
  console.log(props.glbPath);
  const glb = useLoader(GLTFLoader, props.glbPath, (loader) => {
    dracoLoader.setDecoderPath("../libs/draco/");
    loader.setDRACOLoader(dracoLoader);
  });

  return (
    <>
      {props.lights &&
        props.lights.map((light, index) => (
          <Lights key={index} light={light} />
        ))}
      <group
        scale={props.scaleFactor}
        position={props.position}
        ref={meshRef}
        onClick={handleClick}
      >
        {/* <primitive object={glb.scene} /> */}
        {glb.scene && <primitive object={glb.scene} />}

        {/* {colliders.current.map((collider, index) => (
                    <>{collider}</>
                ))} */}
      </group>
    </>
  );
};

export default SceneLoader;
