import actionTypes from "../action/type";

const initialState = {};
function errorReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ERRORS:
      return action.payload;
    case actionTypes.REMOVE_ERRORS:
      return action.payload;
    default:
      return state;
  }
}
export default errorReducer;
