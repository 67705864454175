import actionTypes from './type'
import axios from 'axios'
import setAuthToken from '../setAuthToken'
import { toast } from 'react-toastify'
import { CustomToast } from '../../common/commonFunction'
import { Check2 } from 'react-bootstrap-icons'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
export const getByPath = (path, data, name, method) => async (dispatch) => {
  // toast.success(
  //   <CustomToast
  //     icon={<Check2 size={20} color="#fff" />}
  //     title={"Success"}
  //     description={"USER LOGGED OUT Successfully"}
  //   />
  // );

  dispatch({
    type: actionTypes.SET_ERRORS,
    payload: {},
  })
  dispatch({
    type: actionTypes.LOADINGSTART,
  })
  await axios[method](path, data && data)
    .then((res) => {
      let { data } = res.data

      if (data && name === 'LOGIN_USER') {
        // console.log(data, "data");
        const { token } = data
        sessionStorage.setItem('token', token)
        localStorage.setItem('userInfo', JSON.stringify(data))
        // setAuthToken(token);
        // navigate(reDirect);
        let user = JSON.parse(localStorage.getItem('userInfo'))
        setAuthToken(user.token)
        // console.log(res, "res1");
        dispatch({
          type: name,
          payload: data,
        })
      } else if (
        name === 'LOGIN_USER' ||
        res.data.status === 200 ||
        res.data.success === 200
      ) {
        dispatch({
          type: name,
          payload: data,
        })
        // console.log(res, 'put response here')
        // console.log(method, 'put method here response here')
        if (
          method !== 'get' ||
          method === 'post' ||
          method === 'put' ||
          method === 'delete'
        ) {
          // toast.success(res.data.msg, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 1500,
          // });
          console.log(res, 'put response here')
          toast.success(
            <CustomToast
              icon={<Check2 size={20} color='#fff' />}
              title={'Success'}
              description={res.data.msg}
            />
          )
        }
      } else {
        toast.error(
          <CustomToast
            icon={<Check2 size={20} color='#fff' />}
            title={'Error'}
            description={res.data.msg}
          />
        )
      }
      dispatch({
        type: actionTypes.LOADINGSTOP,
      })
    })
    .catch((error) => {
      // console.log(error, 'res4')
      dispatch({
        type: actionTypes.LOADINGSTOP,
      })
      dispatch({
        type: actionTypes.SET_ERRORS,
        payload: error.response.data,
      })
      toast.error(
        <CustomToast
          icon={<Check2 size={20} color='#fff' />}
          title={'Error'}
          description={error.response.data.msg}
        />
      )
      // toast.error(error.response.data.msg, {
      //   position: toast.POSITION.TOP_RIGHT,
      // })
    })
}
export const logoutUser = () => (dispatch) => {
  sessionStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('storeData')
  localStorage.removeItem('persist:root')
  toast.success(
    <CustomToast
      icon={<Check2 size={20} color='#fff' />}
      title={'Success'}
      description={'USER LOGGED OUT Successfully'}
    />
  )

  //   setAuthToken(false);
  dispatch({ type: 'USER_LOGGED_OUT' })
}
