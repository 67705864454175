import React, { useState } from "react";
import Background from "../../../../assests/vosmos/img/CityTop2.jpg";
import VosmosLogo from "../../../../assests/vosmos/img/logo.png";
import SignUpForm from "../signUp/SignUpForm";
// import LoginForm from "./LoginForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getByPath } from "../../../../store/action/authAction";
import actionTypes from "../../../../store/action/type";
import Config from "../../../../config";
// import '../../../../../src/index.css'
// import '../../../../assests/vosmos/css/custom.css'
import LoginNew from "../login/LoginNew";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import { login } from "../../../store/action/userActions";
// import { useNavigate } from "react-router-dom";
import configData from "../../../../config";

const ForgetPassword = () => {
  // console.log("hello");
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [forget, setForget] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.authReducer);
  const { isAuthenticated, isLoading } = storeData;
  const forgetHandler = () => {
    var values = { email, link: window.location + "changePassword" };

    dispatch(
      getByPath(
        Config.apiBasePath + Config.endPoints.forgetPassword,
        values,
        "",
        Config.method.post
        // "/dashboard"
      )
    );
    setTimeout(() => navigate("/"), 1500);
  };

  return (
    <>
      <form style={{ display: forget ? "" : "none" }}>
        <div className="form-floating mb-1 col-lg-8">
          <input
            type="email"
            className="form-control inputStyle1 ps-1"
            id="emailID"
            placeholder="Enter Email ID"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="emailID" className="ps-0">
            Enter Email ID
          </label>
        </div>
        <button
          type="button"
          className="btn btn-sm btnTeal px-4 py-2 mt-4 showOtp"
          onClick={forgetHandler}
        >
          Submit
        </button>
        <p className="mt-5 fs-12">
          Don’t have an account?{" "}
          {/* <a href='{configData.route.register}' className='showSignUp'>
          </a> */}
          <Link
            onClick={() => {
              setRegister(true);
              setForget(false);
            }}
            className="showSignUp"
          >
            SIGN UP
          </Link>
          OR
          <Link
            onClick={() => {
              setLogin(true);
              setForget(false);
            }}
            className="showSignUp"
          >
            SIGN IN
          </Link>
        </p>
      </form>
      {forget ? "" : login ? <LoginNew /> : register ? <SignUpForm /> : ""}
    </>
  );
};

export default ForgetPassword;
