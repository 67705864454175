const apiBasePath = "https://meta-api.vosmos.live";
// if (process.env.NODE_ENV === "development") {
//   //apiBasePath = "http://localhost:5002";
// } else {
//   //
// }

const configData = {
  apiBasePath: apiBasePath,
  apiAdminPath: apiBasePath + "/admin",
  apiOwnerPath: apiBasePath + "/owner",
  apiUploadPath: apiBasePath + "/uploads",
  apiMetaversePath: apiBasePath + "/metaverse",
  //api end point
  endPoints: {
    updateUser: "/updateUser",
    forgetPassword: "/forgetPassword",
    registerUser: "/register",
    loginUser: "/login",
    addNewTemplate: "/addNewTemplate",
    manageTemplate: "/manageTemplate",
    deleteTemplate: "/deleteTemplate",
    manageStores: "/manageStores",
    manageStore: "/manageStore",
    activeStores: "/activeStores",
    updateStore: "/updateStore",
    saveStore: "/saveStore",
    deleteStore: "/deleteStore",
    settingmaster: "/settingmaster",
    manageProducts: "/manageProducts",
    manageProduct: "/manageProduct",
    deleteProduct: "/deleteProduct",
    poll: "/poll",
    getAllfeedBack: "/getAllfeedBack",
    getAllNotification: "/getAllNotification",
    addNewProduct: "/addNewProduct",
    updateProduct: "/updateProduct",
    deletePoll: "/deletePoll",
    deleteFeedBack: "/deleteFeedBack",
    deleteNotification: "/deleteNotification",
    savePoll: "/savePoll",
    updatePoll: "/updatePoll",
    updateFeedBack: "/updateFeedBack",
    updateNotification: "/updateNotification",
    uploadFileToUser: "/UploadFileToUser",
    gallery: "/gallery",
    uploadFileToGallery: "/UploadFileToGallery",
    saveFeedBack: "/saveFeedBack",
    saveNotification: "/saveNotification",
    getfeedBack: "/getfeedBack",
    getNotification: "/getNotification",
    getSalesCharacterInfo: "/getSalesCharacterInfo",
    UpdateSalesCharacterInfo: "/UpdateSalesCharacterInfo",
    category: "/category",
    getStore: "/getStore",
    deletefile: "/deletefile",
  },
  route: {
    basePath: "/",
    notFound: "*",
    login: "/",
    register: "register",
    dashboard: "dashboard",
    ownerDashboard: "/owner/dashboard",
    metaverse: "metaverse",
    manageStore: "owner/store",
    addNewStore: "owner/addNewStore",
    manageProducts: "owner/product",
    addNewProduct: "owner/addNewProduct",
    poll: "owner/poll",
    addNewPoll: "owner/addNewPoll",
    addNewFeedback: "owner/addNewFeedback",
    updateFeedBack: "owner/updateFeedBack",
    updatePoll: "owner/updatePoll",
    getAllfeedBack: "owner/feedBack",
    getAllNotification: "owner/notification",
    addNewNotification: "owner/addNewNotification",
  },
  navigatePath: {},
  method: {
    post: "post",
    get: "get",
    delete: "delete",
    put: "put",
  },
};

export default configData;
